import axios from "axios";

let BASE_URL = undefined;
if (process.env.NODE_ENV === "production") {
    BASE_URL = process.env.REACT_APP_PROJECT === "KLERP" ? "https://klerpdemo.klerptech.com/api" : "https://api.ssrmotorsport.com/api";
   
} else if (process.env.NODE_ENV === "development") {
    BASE_URL = "http://localhost:5000/api/";
}

export const publicRequest = axios.create({
    baseURL: BASE_URL,
    headers: { token: "&KZqXDh/Trf.ooWOdqX}Y)g@$'2UO0}09m9v1?BzcGum#RVVPH" },
  });
  
  export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: { token: "&KZqXDh/Trf.ooWOdqX}Y)g@$'2UO0}09m9v1?BzcGum#RVVPH" },
  });
  