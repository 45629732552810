import "./warrantyList.css";
import { Close } from "@material-ui/icons";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getWarranties,
    returnWarranty,
    completeWarranty,
} from "../../redux/apiCalls";
import { Link } from "react-router-dom";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import "./warrantyList.css";
import Table from "../../components/advancedTable";
import _ from "lodash";

const statusOptions = [
    {
        label: "Inactive",
        value: "inactive",
    },
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Return",
        value: "return",
    },
    {
        label: "All",
        value: "",
    },
];

export default function ReturnList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const specialAccess = useSelector((state) =>
        state.user.currentUser
            ? ["64be53a291c85522064a711a", "6426333a94c2157f1ba818b9"].includes(
                  state.user.currentUser._id
              )
            : null
    );
    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const warranties = useSelector((state) => state.warranty.warranties);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [warrantyData, setWarrantyData] = useState([]);
    const [returnWarrantyMenu, setReturnWarrantyMenu] = useState(false);
    const [completedWarrantyMenu, setCompletedWarrantyMenu] = useState(false);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [model, setModel] = useState(undefined);
    const [remarks, setRemarks] = useState(undefined);
    const [servicePrice, setServicePrice] = useState(0);
    const [inputs, setInputs] = useState({
        model: "",
        userId: specialAccess
            ? "63429be7a4b0715d61b8955c"
            : manager
            ? principle
            : staff
            ? principle
            : undefined,
        limit: 20,
        page: 1,
        status: "active",
        serialNumber: "",
        dateTo: undefined,
        dateFrom: undefined,
    });
    const [usersData, setUsersData] = useState([]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    useEffect(() => {
        if (returnWarrantyMenu === true) {
            window.scroll(0, 0);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [returnWarrantyMenu]);

    useEffect(() => {
        if (completedWarrantyMenu === true) {
            window.scroll(0, 0);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [completedWarrantyMenu]);

    useEffect(() => {
        const fetchData = async () => {
            if (isLoaded === false) {
                try {
                    const result = await getWarranties(dispatch, inputs);
                    setWarrantyData(result.payload);
                    setIsLoaded(true);
                } catch (error) {
                    console.error("Error fetching warranties:", error);
                }
            }
        };

        fetchData();
    }, [dispatch, inputs, warrantyData, isLoaded]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    const handleReturn = (inputs) => {
        returnWarranty(inputs, dispatch);
    };

    const handleComplete = (id) => {
        completeWarranty(id, dispatch);
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, status: e.value };
        });
    };

    const handleModelChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, model: e.target.value };
        });
        setModel(e.target.value);
    };

    const handleSerialChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, serialNumber: e.target.value };
        });
        setSerialNumber(e.target.value);
    };

    const handleOutletChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, page: 1, outlet: e.value };
        });
    };
    

    const hqColumns = useMemo(
        () => [
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "user",
                Header: "User",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "startDate",
                Header: "Warranty Until",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).add(3, "y").format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                accessor: "action",
                Header: "Action",
                width: 800,
                sortable: false,
                Cell: (params) => {
                    return (
                        <div className="flex">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to={"/warranty/" + params.row.original._id}
                            >
                                <button className="productListEdit warrantyWidth">
                                    Transfer
                                </button>
                            </Link>
                            {params.row.original.status === "return" ? (
                                <div
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    <div
                                        className="blue productListEdit warrantyWidth"
                                        onClick={() => {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setCompletedWarrantyMenu(true);
                                        }}
                                    >
                                        Completed
                                    </div>
                                </div>
                            ) : params.row.original.status === "active" ? (
                                <div
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    <div
                                        className="red productListEdit warrantyWidth"
                                        onClick={() => {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setReturnWarrantyMenu(true);
                                        }}
                                    >
                                        Return
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                },
            },
        ],
        []
    );

    const columns = useMemo(
        () => [
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "user",
                Header: "User",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "startDate",
                Header: "Activated Date",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value ? moment(value).format("DD-MM-YYYY") : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                accessor: "action",
                Header: "Action",
                width: 800,
                sortable: false,
                Cell: (params) => {
                    return (
                        <div className="flex">
                            {params.row.original.status === "return" ? (
                                <div
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    <div
                                        className="blue productListEdit warrantyWidth"
                                        onClick={() => {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setCompletedWarrantyMenu(true);
                                        }}
                                    >
                                        Completed
                                    </div>
                                </div>
                            ) : params.row.original.status === "active" ? (
                                <div
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    <div
                                        className="red productListEdit warrantyWidth"
                                        onClick={() => {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setReturnWarrantyMenu(true);
                                        }}
                                    >
                                        Return
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <div className="productList ">
            <div className="samerow">
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="serialNumber"
                        value={serialNumber}
                        type="string"
                        placeholder="Serial Number"
                        onChange={handleSerialChange}
                    />
                </div>
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="model"
                        value={model}
                        type="string"
                        placeholder="Model (Select Export Format)"
                        onChange={handleModelChange}
                    />
                </div>

                <div className="sameRowItem marginRight">
                    <Select
                        options={statusOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        value={{
                            label: _.capitalize(inputs.status),
                            value: inputs.status,
                        }}
                        onChange={handleInputStatus}
                        placeholder="Status"
                    />
                </div>

                <div className="sameRowItem mb-2 marginRight">
                    {(superadmin || specialAccess) && (
                        <>
                            <Select
                                options={usersData.map((user, i) => {
                                    return {
                                        label: `${user.name}`,
                                        value: user._id,
                                    };
                                })}
                                onChange={handleOutletChange}
                                placeholder={"Outlet"}
                            />
                        </>
                    )}
                </div>
            </div>
            {isLoaded === true && (
                <Table
                    data={warrantyData}
                    columns={superadmin ? hqColumns : columns}
                    pageCount={Math.floor(warranties.totalPages / 10) + 2}
                    totalCount={warranties.totalPages}
                    updatePagination={setInputs}
                    paginationState={inputs}
                    updateLoaded={setIsLoaded}
                    hidePagination={inputs.limit === 10 ? false : true}
                />
            )}
            {superadmin && (
                <div className="reportContainer">
                    <div className="featuredReport blue">
                        <span className="featuredTitle">Total Stock:</span>
                        <div className="featuredReportContainer">
                            <span className="featuredMoney">
                                {" "}
                                {warranties.totalPages}
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {returnWarrantyMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setReturnWarrantyMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <div className="paymentTypeContainer">
                                    <div className="remarksContainer">
                                        <label className="block px-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Remarks
                                        </label>
                                        <textarea
                                            className="block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            name="remarks"
                                            type="textarea"
                                            rows="3"
                                            cols="100"
                                            onChange={(e) => {
                                                setRemarks(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="buttonContainer">
                                    <button
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleReturn({
                                                id: modalData,
                                                remarks,
                                            });
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setReturnWarrantyMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {completedWarrantyMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setReturnWarrantyMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <div className="paymentTypeContainer">
                                    <div className="remarksContainer">
                                        <label className="block px-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Service Price
                                        </label>
                                        <input
                                            name="service"
                                            type="number"
                                            onChange={(e) => {
                                                setServicePrice(e.target.value);
                                            }}
                                            defaultValue={0}
                                        />
                                    </div>
                                </div>

                                <div className="buttonContainer">
                                    <button
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleComplete({
                                                id: modalData,
                                                servicePrice,
                                            });
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setCompletedWarrantyMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
