const outletList = [
    {
        id: "63429be7a4b0715d61b8955c",
        name: "Haofa HQ",
    },
    {
        id: "6374efeb14bab20ec5c92613",
        name: "Desmond",
    },
    {
        id: "63b656df673e79b4b6c0617b",
        name: "StarTed IOI Mall",
    },
    {
        id: "63b694031e254a64d5781cc7",
        name: "StarTed Sunway Pyramid",
    },
    {
        id: "63ff2a38380819f085c75b51",
        name: "Haofa JB",
    },
    {
        id: "6426327094c2157f1ba818b4",
        name: "HAOFA Velocity",
    },
    {
        id: "660d158b52646d3d009568bc",
        name: "HAOFA One Utama",
    },
    {
        id: "64366cc03397fff7cecec9da",
        name: "Haofa KOL",
    },
    {
        id: "64be53a291c85522064a711a",
        name: "Haofa Joey",
    },
    {
        id: "650fd004fff54942628a7c75",
        name: "HAOFA Tourbillon Singapore",
    },
    {
        id: "650fd3f5fff54942628a7c8f",
        name: "HAOFA Ecommerce",
    },
    {
        id: "651bcc9c02884c24cb9a4975",
        name: "Dekairos Subang",
    },
    {
        id: "652a13954da942d539ff8739",
        name: "The bigbang.shop",
    },
    {
        id: "6583cd594b2f8e4c19919acf",
        name: "Thebigbang.shop@163",
    },
    {
        id: "66028fbb8411bff39d4ce29b",
        name: "Haofa Admin"
    },
    {
        id: "64be53a291c85522064a711b",
        name: "Haofa Live"
    },
    {
        id: "6628d814221cebabf321b9ab",
        name: "Haofa Repair"
    }
    
];

export default outletList;