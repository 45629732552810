import { useLocation } from "react-router-dom";
import "./product.css";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateProduct } from "../../redux/apiCalls";
import Select from "react-select";
import axios from "axios";
import { generateSignature } from "../../redux/apiCalls";
import { publicRequest } from "../../requestMethods";

export default function Product() {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [inputs, setInputs] = useState({});
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);

    const product = useSelector((state) =>
        state.product.products.find((product) => product._id === productId)
    );

    const getAllBrands = async () => {
        const res = await publicRequest.get("/brand/all", {
            params: { isAdmin: true },
        });
        setBrands(res.data);
    };

    useEffect(() => {
        if (brands.length === 0) {
            getAllBrands();
        }
    }, [brands]);

    const getAllCategories = async () => {
        const res = await publicRequest.get("/category/all", {
            params: { isAdmin: true },
        });
        setCategories(res.data);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getAllCategories();
        }
    }, [categories]);
    

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleBrandChange = (e) => {
        setInputs((prev) => {
            return { ...prev, brand: e.value };
        });
    };

    const handleCategoryChange = (e) => {
        setInputs((prev) => {
            return { ...prev, category: e.value };
        });
    };

    const handleClick = async () => {
        let imageupload = undefined
        if (file == null) {
            imageupload = product.img
        } else {
            const fileName = new Date().getTime() + file.name;
            const imageresult = await generateSignature();
            const imageFormData = new FormData();
    
            imageFormData.append("file", file);
            imageFormData.append(
                "publicKey",
                "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
            );
            imageFormData.append("signature", imageresult.data.signature);
            imageFormData.append("expire", imageresult.data.expire);
            imageFormData.append("token", imageresult.data.token);
            imageFormData.append(
                "fileName",
                `${fileName} - ${imageresult.data.expire}`
            );
            imageFormData.append("folder", "SSR");
    
            const imageUploadResult = await axios.post(
                "https://upload.imagekit.io/api/v1/files/upload",
                imageFormData,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Accept: "application/json",
                    },
                }
            );
            imageupload = imageUploadResult.data.url
        }

        const updatedProduct = {
            ...product,
            title: inputs.title ? inputs.title : product.title,
            brand: inputs.brand ? inputs.brand : product.brand,
            category: inputs.category ? inputs.category : product.category,
            stock: inputs.stock ? inputs.stock : product.stock,
            img: imageupload,
        };
        await updateProduct(product._id, updatedProduct, dispatch);
    };

    return (
        <div className="product">
            <h1 className="addProductTitle">Update Product</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Title</label>
                    <input
                        name="title"
                        type="text"
                        placeholder={product.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Stocks</label>
                    <input
                        name="stock"
                        type="number"
                        placeholder={product.stock}
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Category</label>
                    <Select
                        options={categories.map((product, i) => {
                            return { label: product.name, value: product._id };
                        })}
                        onChange={handleCategoryChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Brand</label>
                    <Select
                        options={brands.map((product, i) => {
                            return { label: product.name, value: product._id };
                        })}
                        onChange={handleBrandChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>
                <div
                    onClick={() => handleClick()}
                    className="updateProductButton"
                >
                    Update Product
                </div>
            </div>
        </div>
    );
}
