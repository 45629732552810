import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { generateSignature } from "../../redux/apiCalls";
import { addProductGallery } from "../../redux/apiCalls";
import axios from "axios";

export default function ProductGallery() {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();

    const product = useSelector((state) =>
        state.product.products.find((product) => product._id === productId)
    );

    const handleClick = async () => {
        if (file == null) {
            return alert("Image is required for creating new product");
        }

        const fileName = new Date().getTime() + file.name;
        const imageresult = await generateSignature();
        const imageFormData = new FormData();

        imageFormData.append("file", file);
        imageFormData.append(
            "publicKey",
            "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
        );
        imageFormData.append("signature", imageresult.data.signature);
        imageFormData.append("expire", imageresult.data.expire);
        imageFormData.append("token", imageresult.data.token);
        imageFormData.append(
            "fileName",
            `${fileName} - ${imageresult.data.expire}`
        );
        imageFormData.append("folder", "SSR");

        const imageUploadResult = await axios.post(
            "https://upload.imagekit.io/api/v1/files/upload",
            imageFormData,
            { headers: { "Content-Type": "multipart/form-data" } }
        );

        const updatedProduct = {
            img: imageUploadResult.data.url,
        };
        addProductGallery(product._id, updatedProduct, dispatch);
    };

    return (
        <div className="product">
            <h1 className="addProductTitle">Add Product Gallery</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Add Product Gallery
                </button>
            </div>
            <div className="marginTop">
                <img
                    alt="product gallery 1"
                    className="galleryImage"
                    src={product.gallery[0] ? product.gallery[0].img : ""}
                ></img>
                <img
                    alt="product gallery 2"
                    className="galleryImage"
                    src={product.gallery[1] ? product.gallery[1].img : ""}
                ></img>
                <img
                    alt="product gallery 3"
                    className="galleryImage"
                    src={product.gallery[2] ? product.gallery[2].img : ""}
                ></img>
                <img
                    alt="product gallery 4"
                    className="galleryImage"
                    src={product.gallery[3] ? product.gallery[3].img : ""}
                ></img>
            </div>
        </div>
    );
}
