import { useLocation } from "react-router-dom";
import "./productColor.css";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { generateSignature } from "../../redux/apiCalls";
import { addProductColor } from "../../redux/apiCalls";
import axios from "axios";
import Select from "react-select";

export default function ProductColor() {
    const location = useLocation();
    const productId = location.pathname.split("/")[2];
    const [inputs, setInputs] = useState({name: "100"});
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();
    

    const product = useSelector((state) =>
        state.product.products.find((product) => product._id === productId)
    );

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleType = (e) => {
        setInputs((prev) => {
            return { ...prev, type: e.value };
        });
    };

    const handleClick = async () => {
        let imageupload = undefined
        if (file == null) {
            imageupload = "https://ik.imagekit.io/dnddecpho/Haofa/blank_2YRK1Fq9C.png?updatedAt=1680849534401"
        } else {
            const fileName = new Date().getTime() + file.name;
            const imageresult = await generateSignature();
            const imageFormData = new FormData();
    
            imageFormData.append("file", file);
            imageFormData.append(
                "publicKey",
                "public_0THMWX6MF/cuVk9EPEFy+e0R0Qk="
            );
            imageFormData.append("signature", imageresult.data.signature);
            imageFormData.append("expire", imageresult.data.expire);
            imageFormData.append("token", imageresult.data.token);
            imageFormData.append(
                "fileName",
                `${fileName} - ${imageresult.data.expire}`
            );
            imageFormData.append("folder", "SSR");
    
            const imageUploadResult = await axios.post(
                "https://upload.imagekit.io/api/v1/files/upload",
                imageFormData,
                { headers: {'Content-Type': 'multipart/form-data'} }
            );

            imageupload = imageUploadResult.data.url
        }

      

        //SHOULD PUSH COLOR INTO ARRAY NOT REPLACE THE ENTIRE THING
        const updatedProduct = { img: imageupload, name: inputs.name, type: inputs.type, amount: 1, price: parseInt(inputs.price), costprice: parseInt(inputs.costprice), status: "active"};
        addProductColor(product._id, updatedProduct, dispatch);
    };

    return (
        <div className="product">
            <h1 className="addProductTitle">Add Item</h1>
            <div className="addProductItem">
                    <label>Image</label>
                    <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                </div>

                <div className="addProductItem">
                    <label>Product Name</label>
                    <input
                        name="name"
                        type="text"
                        placeholder="Product Name"
                        onChange={handleChange}
                    />
                </div>

                <div className="addProductItem">
                    <label>Price (RM)</label>
                    <input
                        name="price"
                        type="number"
                        placeholder="Enter Price"
                        onChange={handleChange}
                    />
                </div>

                <div className="addProductItem">
                    <label>Cost Price (RM)</label>
                    <input
                        name="costprice"
                        type="number"
                        placeholder="Enter Price"
                        onChange={handleChange}
                    />
                </div>

                <div className="addProductItem">
                    <label>New / Used</label>
                    <Select
    options={[
        { label: 'New', value: "new" },
        { label: 'Used', value: "used" },
    ]}
    onChange={handleType}
/>
                <button onClick={handleClick} className="addProductButton">
                    Add Item
                </button>
            </div>
        </div>
    );
}
