import "./sidebar.css";
import {
    LineStyle,
    Timeline,
    PermIdentity,
    Storefront,
    AttachMoney,
    AccountBalance,
    Add,
    ShoppingCart,
    Receipt,
    Spellcheck,
    AllInbox,
    Security,
    Redeem,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Sidebar() {
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const hq = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.email === "admin@ssrmotorsport.com"
            : null
    );

    if (!hq) {
        return (
            <div className="sidebar">
                <div className="sidebarWrapper">
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Dashboard</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/"
                                className="link"
                            >
                                <li className="sidebarListItem active">
                                    <LineStyle className="sidebarIcon" />
                                    Home
                                </li>
                            </Link>
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/pos"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Timeline className="sidebarIcon" />
                                            POS
                                        </li>
                                    </Link>

                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/orders"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AttachMoney className="sidebarIcon" />
                                            Orders
                                        </li>
                                    </Link>

                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/statements"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Redeem className="sidebarIcon" />
                                            Statements
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Supplier List</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/newsupplier"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Add className="sidebarIcon" />
                                    New Supplier
                                </li>
                            </Link>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/supplier"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Spellcheck className="sidebarIcon" />
                                    Supplier
                                </li>
                            </Link>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/supplierlist"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Receipt className="sidebarIcon" />
                                    Supplier List
                                </li>
                            </Link>
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Assets / Liabilities</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/assets"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Spellcheck className="sidebarIcon" />
                                    Assets
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Installments / Journals</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/installments"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <ShoppingCart className="sidebarIcon" />
                                    Installments
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Trade Ins</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/tradein"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Spellcheck className="sidebarIcon" />
                                    Trade In
                                </li>
                            </Link>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/tradeinlist"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Receipt className="sidebarIcon" />
                                    Trade In List
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Users</h3>
                        <ul className="sidebarList">
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/users"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <PermIdentity className="sidebarIcon" />
                                            Users
                                        </li>
                                    </Link>
                                </>
                            )}
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newuser"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New User
                                        </li>
                                    </Link>
                                </>
                            )}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/history"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <ShoppingCart className="sidebarIcon" />
                                    Customer History
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Vehicles</h3>
                        <ul className="sidebarList">
                            {(superadmin || manager || admin) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/vehicles"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AccountBalance className="sidebarIcon" />
                                            Vehicles
                                        </li>
                                    </Link>
                                </>
                            )}
                            {superadmin && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newvehicle"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            Add Vehicle
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Brand</h3>
                        <ul className="sidebarList">
                            {(superadmin || manager || admin) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/brands"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Security className="sidebarIcon" />
                                            Brands
                                        </li>
                                    </Link>
                                </>
                            )}
                            {superadmin && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newbrand"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            Add Brand
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Category</h3>
                        <ul className="sidebarList">
                            {(superadmin || manager || admin) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/categories"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AllInbox className="sidebarIcon" />
                                            Categories
                                        </li>
                                    </Link>
                                </>
                            )}
                            {superadmin && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newcategory"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            Add Category
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        {(superadmin || admin || marketing) && (
                            <h3 className="sidebarTitle">Products</h3>
                        )}
                        <ul className="sidebarList">
                            {(superadmin || admin || marketing) && (
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/products"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Storefront className="sidebarIcon" />
                                        Products
                                    </li>
                                </Link>
                            )}
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newproduct"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New Product
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        hq && (
            <div className="sidebar">
                <div className="sidebarWrapper">
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Dashboard</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/"
                                className="link"
                            >
                                <li className="sidebarListItem active">
                                    <LineStyle className="sidebarIcon" />
                                    Home
                                </li>
                            </Link>
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/pos"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Timeline className="sidebarIcon" />
                                            POS
                                        </li>
                                    </Link>

                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/orders"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AttachMoney className="sidebarIcon" />
                                            Orders
                                        </li>
                                    </Link>

                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/statements"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Redeem className="sidebarIcon" />
                                            Statements
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Installments / Journals</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/installments"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <ShoppingCart className="sidebarIcon" />
                                    Installments
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Trade Ins</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/tradein"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Spellcheck className="sidebarIcon" />
                                    Trade In
                                </li>
                            </Link>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/tradeinlist"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Receipt className="sidebarIcon" />
                                    Trade In List
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Users</h3>
                        <ul className="sidebarList">
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/users"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <PermIdentity className="sidebarIcon" />
                                            Users
                                        </li>
                                    </Link>
                                </>
                            )}
                            {!accountant && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newuser"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New User
                                        </li>
                                    </Link>
                                </>
                            )}
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/history"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <ShoppingCart className="sidebarIcon" />
                                    Customer History
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Vehicles</h3>
                        <ul className="sidebarList">
                            {(superadmin || manager || admin) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/vehicles"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AccountBalance className="sidebarIcon" />
                                            Vehicles
                                        </li>
                                    </Link>
                                </>
                            )}
                            {superadmin && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newvehicle"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            Add Vehicle
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Brand</h3>
                        <ul className="sidebarList">
                            {(superadmin || manager || admin) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/brands"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Security className="sidebarIcon" />
                                            Brands
                                        </li>
                                    </Link>
                                </>
                            )}
                            {superadmin && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newbrand"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            Add Brand
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>
                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Category</h3>
                        <ul className="sidebarList">
                            {(superadmin || manager || admin) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/categories"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <AllInbox className="sidebarIcon" />
                                            Categories
                                        </li>
                                    </Link>
                                </>
                            )}
                            {superadmin && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newcategory"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            Add Category
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        {(superadmin || admin || marketing) && (
                            <h3 className="sidebarTitle">Products</h3>
                        )}
                        <ul className="sidebarList">
                            {(superadmin || admin || marketing) && (
                                <Link
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    to="/products"
                                    className="link"
                                >
                                    <li className="sidebarListItem">
                                        <Storefront className="sidebarIcon" />
                                        Products
                                    </li>
                                </Link>
                            )}
                            {(superadmin || marketing) && (
                                <>
                                    <Link
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        to="/newproduct"
                                        className="link"
                                    >
                                        <li className="sidebarListItem">
                                            <Add className="sidebarIcon" />
                                            New Product
                                        </li>
                                    </Link>
                                </>
                            )}
                        </ul>
                    </div>

                    <div className="sidebarMenu">
                        <h3 className="sidebarTitle">Supplier List</h3>
                        <ul className="sidebarList">
                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/newsupplier"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Add className="sidebarIcon" />
                                    New Supplier
                                </li>
                            </Link>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/supplier"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Spellcheck className="sidebarIcon" />
                                    Supplier
                                </li>
                            </Link>

                            <Link
                                style={{
                                    textDecoration: "none",
                                    color: "black",
                                }}
                                to="/supplierlist"
                                className="link"
                            >
                                <li className="sidebarListItem">
                                    <Receipt className="sidebarIcon" />
                                    Supplier List
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>
            </div>
        )
    );
}
