import "./warrantyList.css";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getStockCheck,
} from "../../redux/apiCalls";
import excel from "../../components/excel/excel";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import "./warrantyList.css";
import Table from "../../components/advancedTable";
import outletList from "../../outletList";


export default function StockList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const adminOutlet = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser._id : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const marketing = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "marketing"
            : null
    );

    const stockKeeper = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.isStockKeeper : null
);

        const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const specialAccess = useSelector((state) =>
        state.user.currentUser
            ? ["64be53a291c85522064a711a", "6426333a94c2157f1ba818b9"].includes(
                  state.user.currentUser._id
              )
            : null
    );

    const NumberFormatter = new Intl.NumberFormat("en-GB", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const [isLoading, setIsLoading] = useState(false);
    const warranties = useSelector((state) => state.warranty.warranties);
    const [isLoaded, setIsLoaded] = useState(false);
    const [warrantyData, setWarrantyData] = useState([]);
    const [category, setCategory] = useState("");
    const [brands, setBrands] = useState([]);
    const [brand, setBrand] = useState("");
    const [inputs, setInputs] = useState({
        model: "",
        userId: 
            specialAccess
            ? "63429be7a4b0715d61b8955c"
            : manager
            ? principle
            : staff
            ? principle
            : superadmin 
            ? "63429be7a4b0715d61b8955c"
            : stockKeeper
            ? "63429be7a4b0715d61b8955c"
            : marketing
            ? adminOutlet
            : undefined,
        limit: 100,
        page: 1,
        status: "",
        serialNumber: "",
        endOn: undefined,
        dateFrom: undefined,
        outlet: specialAccess
            ? ""
            : superadmin
            ? ""
            : stockKeeper
            ? ""
            : manager
            ? principle
            : marketing
            ? adminOutlet
            : adminOutlet,
        category: undefined,
        brand: undefined
    });
    const [usersData, setUsersData] = useState([]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const getAllBrands = async () => {
        const res = await publicRequest.get("/brand/all", {
            params: { isAdmin: true },
        });
        setBrands(res.data);
    };

    useEffect(() => {
        if (brands.length === 0) {
            getAllBrands();
        }
    }, [brands]);
    

    useEffect(() => {
        const fetchData = async () => {
            if (isLoaded === false) {
                try {
                    setIsLoading(true)
                    const result = await getStockCheck(dispatch, inputs);
                    if (result) {
                        setIsLoading(false)
                        setWarrantyData(result.payload);
                        setIsLoaded(true);
                    }
  
                } catch (error) {
                    console.error("Error fetching warranties:", error);
                }
            }
        };

        if (warrantyData[0] === undefined) {
            fetchData();
        }
    }, [dispatch, inputs, warrantyData, isLoaded]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    const handleFilter = (e) => {
        setCategory(e.target.value);
        setWarrantyData(
            warranties.payload.filter(
                (warranty) =>
                    warranty.category.includes(e.target.value) &&
                warranty.brand.includes(brand)
            )
        );

        setInputs((prev) => {
            return { ...prev, category: e.target.value };
        });
    };

    const handleBrand = (e) => {
        setWarrantyData(
                    warranties.payload.filter(
                        (warranty) =>
                            warranty.brand.includes(e.label) &&
                        warranty.category.includes(category)
                    )
                );
        setBrand(e.value)
        setInputs((prev) => {
            return { ...prev, brand: e.value };
        })
    };
    
    const hqColumns = useMemo(
        () => [
            {
                accessor: "brand",
                Header: "Brand",
                width: 200,
                sortable: false,
            },
            {
                accessor: "category",
                Header: "Category",
                width: 200,
                sortable: false,
            },
            {
                accessor: "model",
                Header: "Model",
                width: 200,
                sortable: false,
            },
            {
                accessor: "amount",
                Header: "Stock",
                width: 200,
                sortable: false,
            },
            {
                accessor: "costprice",
                Header: "Cost",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">RM {value}</div>,
            },
            {
                accessor: "totalprice",
                Header: "Total Price",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">RM {value}</div>,
            },
        ],
        []
    );

    const columns = useMemo(
        () => [
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "user",
                Header: "User",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name ? value.name : "" : ""}</div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 200,
                sortable: false,
                Cell: ({ value }) => {
                    const selectedOutlet = outletList.find(
                        (outlet) => outlet.id === value
                    );
                    return (
                        <div className="capitalize">
                          {value ? selectedOutlet ? selectedOutlet.name : "" : ""}
                        </div>
                    );
                },
            },
            {
                accessor: "createdAt",
                Header: "Start Date",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "startDate",
                Header: "Warranty Until",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).add(3, "y").format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
        ],
        []
    );

    return (
        <>
        {isLoading ? (
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        ) : (
        <div className="productList ">
            <div className="samerow">
            <div className="addProductItem marginRight">
                    <input
                        className="inputFilter"
                        name="title"
                        value={category}
                        type="string"
                        placeholder="Category"
                        onChange={handleFilter}
                    />
                </div>
                <div className="sameRowItem marginRight">
                        <Select
                            options={brands.map((status, i) => {
                                return {
                                    label: status.name,
                                    value: status._id,
                                };
                            })}
                            onChange={handleBrand}
                            placeholder="Search Brand"
                        />
                    </div>

            </div>
            <div className="block mobileGrid">
                {superadmin && (
                    <>
                    <button
                        onClick={() => {
                            let payload = [];
                            warrantyData.forEach((warranty) => {

                                payload.push({
                                    Brand: warranty.brand,
                                    Category: warranty.category,
                                    Model: warranty.model,
                                    Stock: warranty.amount,
                                    Costprice: `RM ${NumberFormatter.format(warranty.costprice)}`,
                                    Price: `RM ${NumberFormatter.format(warranty.price)}`,
                                    Totalprice: `RM ${NumberFormatter.format(warranty.totalprice)}`,
                                    Type: warranty.type,
                                
                                });
                            });
                            excel({
                                excelData: payload,
                                fileName: "SSRStocks",
                            });
                        }}
                        className="red buttonTop productListButton"
                    >
                        Export
                    </button>
                     <button
                     className="buttonTop productListButton"
                     onClick={async () => {
                        setWarrantyData(
                            warranties.payload
                        );
                        setBrand("");
                        setCategory("");
                     }}
                 >
                     Reset
                 </button>
                 </>
                )}
            </div>
            {isLoaded === true && (
                <Table
                    data={warrantyData}
                    columns={superadmin ? hqColumns : columns}
                    pageCount={Math.floor(warranties.totalPages / 10) + 2}
                    totalCount={warranties.totalPages}
                    updatePagination={setInputs}
                    paginationState={inputs}
                    updateLoaded={setIsLoaded}
                    hidePagination={inputs.limit === 10 ? false : true}
                />
            )}
        </div>
        )}
        </>
    );
}
