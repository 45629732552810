import { createSlice } from "@reduxjs/toolkit";

const campaignSlice = createSlice({
    name: "ssrcampaign",
    initialState: {
        campaign: [],
        isFetching: false,
        error: false,
    },
    reducers: {
       
        getCampaignsStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCampaignsSuccess: (state, action) => {
            state.isFetching = false;
            state.campaign = action.payload;
        },
        getCampaignsFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        voidCampaignStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        acceptCampaignStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
    },
});

export const {
    getCampaignsStart,
    getCampaignsSuccess,
    getCampaignsFailure,
    voidCampaignStart,
    acceptCampaignStart,
} = campaignSlice.actions;
export default campaignSlice.reducer;
