import { useState } from "react";
import { addCategory } from "../../redux/apiCalls";
import Select from "react-select";

const statusOptions = [
    {
        label: "Parts",
        value: "parts",
    },
    {
        label: "Sport Rim",
        value: "sportrim",
    },
    {
        label: "Material",
        value: "material",
    },
    {
        label: "Tyre",
        value: "tyre",
    },
    {
        label: "Lubricant",
        value: "lubricant",
    },
    {
        label: "Accessory",
        value: "accessory",
    },
    {
        label: "Labour",
        value: "labour",
    },
    {
        label: "Package",
        value: "package",
    },
];

export default function NewCategory() {
    const [inputs, setInputs] = useState({});

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleInputStatus = (e) => {
        setInputs((prev) => {
            return { ...prev, page: 1, type: e.value };
        });
    };

    const handleClick = async (e) => {
        const category = { ...inputs };
        e.preventDefault();
        try {
            addCategory({ category });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <div className="newProduct">
                <h1 className="addProductTitle">New Category</h1>
                <div className="addProductForm">
                    <div className="addProductItem">
                        <label className="black required">Name</label>
                        <input
                            name="name"
                            type="text"
                            placeholder="Name"
                            onChange={handleChange}
                        />
                    </div>

                    <div className="sameRowItem marginRight">
                        <Select
                            options={statusOptions.map((status, i) => {
                                return {
                                    label: status.label,
                                    value: status.value,
                                };
                            })}
                            onChange={handleInputStatus}
                            placeholder="Type"
                        />
                    </div>
                    <button onClick={handleClick} className="updateProductButton">
                        Create
                    </button>
                </div>
            </div>
        </>
    );
}
