import "./warrantyList.css";
import { Close } from "@material-ui/icons";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteWarranty,
    getWarranties,
    returnWarranty,
    completeWarranty,
} from "../../redux/apiCalls";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import "./warrantyList.css";
import Table from "../../components/advancedTable";

const statusOptions = [
    {
        label: "Stock",
        value: "inactive",
    },
    {
        label: "Sold",
        value: "active",
    },
    {
        label: "Banned",
        value: "banned",
    },
    {
        label: "All",
        value: "",
    },
];

export default function WarrantyList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );

    const staff = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.type === "staff" : null
);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const specialAccess = useSelector((state) =>
        state.user.currentUser
            ? ["64be53a291c85522064a711a", "6426333a94c2157f1ba818b9"].includes(
                  state.user.currentUser._id
              )
            : null
    );
    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const warranties = useSelector((state) => state.warranty.warranties);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [warrantyData, setWarrantyData] = useState([]);
    const [returnWarrantyMenu, setReturnWarrantyMenu] = useState(false);
    const [completedWarrantyMenu, setCompletedWarrantyMenu] = useState(false);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [model, setModel] = useState(undefined);
    const [servicePrice, setServicePrice] = useState(undefined);
    const [remarks, setRemarks] = useState(undefined);
    const [inputs, setInputs] = useState({
        model: "",
        userId: 
            specialAccess
            ? "63429be7a4b0715d61b8955c"
            :superadmin 
            ? "63429be7a4b0715d61b8955c"
            : manager
            ? principle
            : staff
            ? principle
            : undefined,
        limit: 20,
        page: 1,
        status: "",
        serialNumber: "",
        dateTo: undefined,
        dateFrom: undefined,
    });
    const [usersData, setUsersData] = useState([]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };
    

    useEffect(() => {
        if (returnWarrantyMenu === true) {
            window.scroll(0, 0);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [returnWarrantyMenu]);

    useEffect(() => {
        if (completedWarrantyMenu === true) {
            window.scroll(0, 0);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [completedWarrantyMenu]);

    useEffect(() => {
        if (deleteModalState === true) {
            window.scroll(0, 0);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [deleteModalState]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getWarranties(dispatch, inputs);
                setWarrantyData(result.payload);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching warranties:", error);
            }
        };
    
        if (isLoaded === false) {
            fetchData();
        }
    }, [dispatch, inputs, isLoaded]);
    

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    const handleReturn = (inputs) => {
        returnWarranty(inputs, dispatch);
    };

    const handleComplete = ({ id, servicePrice }) => {
        completeWarranty({ id, servicePrice }, dispatch);
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs(prev => ({
            ...prev,
            page: 1,
            status: e.value
          }));
          
    };

    const handleModelChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, model: e };
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleModelChange(model);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [model]);

    const handleDelayedModelChange = (e) => {
        setModel(e.target.value);
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    const handleDelayedSerialChange = (e) => {
        setSerialNumber(e.target.value);
    };

    const handleOutletChange = (e) => {
        setIsLoaded(false);
        setInputs(prev => ({
            ...prev,
            page: 1,
            outlet: e.value
          }));
    };

    const handleDelete = useCallback(
        (id) => {
            deleteWarranty(id, dispatch);
        },
        [dispatch]
    );

    const hqColumns = useMemo(
        () => [
            {
                accessor: "user",
                Header: "User",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "product",
                Header: "Product",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.model : ""}</div>
                ),
            },
            {
                accessor: "quantity",
                Header: "Quantity",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="">{value ? value : 0} g</div>
                ),
            },
        ],
        []
    );

    const columns = useMemo(
        () => [
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "user",
                Header: "User",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 200,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "startDate",
                Header: "Activated Date",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value ? moment(value).format("DD-MM-YYYY") : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
            {
                accessor: "action",
                Header: "Action",
                width: 600,
                sortable: false,
                Cell: (params) => {
                    return (
                        <div className="flex justify-start">
                            {params.row.original.status === "return" ? (
                                <div
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    <div
                                        className="blue productListEdit warrantyWidth"
                                        onClick={() => {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setCompletedWarrantyMenu(true);
                                        }}
                                    >
                                        Completed
                                    </div>
                                </div>
                            ) : params.row.original.status === "active" ? (
                                <div
                                    style={{
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                >
                                    <div
                                        className="red productListEdit warrantyWidth"
                                        onClick={() => {
                                            setModalData(
                                                params.row.original._id
                                            );
                                            setReturnWarrantyMenu(true);
                                        }}
                                    >
                                        Return
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                },
            },
        ],
        []
    );

    return (
        <div className="productList ">
            <div className="samerow">
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="serialNumber"
                        value={serialNumber}
                        type="string"
                        placeholder="Serial Number"
                        onChange={handleDelayedSerialChange}
                    />
                </div>
                <div className="addProductItem">
                    <input
                        className="inputFilter"
                        name="model"
                        value={model}
                        type="string"
                        placeholder="Model (Select Export Format)"
                        onChange={handleDelayedModelChange}
                    />
                </div>

                <div className="sameRowItem marginRight">
                    <Select
                        options={statusOptions.map((status, i) => {
                            return {
                                label: status.label,
                                value: status.value,
                            };
                        })}
                        onChange={handleInputStatus}
                        placeholder="Status"
                    />
                </div>

                <div className="sameRowItem mb-2 marginRight">
                    {(superadmin || specialAccess) && (
                        <>
                            <Select
                                options={usersData.map((user, i) => {
                                    return {
                                        label: `${user.name}`,
                                        value: user._id,
                                    };
                                })}
                                onChange={handleOutletChange}
                                placeholder={"Outlet"}
                            />
                        </>
                    )}
                </div>
            </div>
            {isLoaded === true && (
                <Table
                    data={isLoaded ? warrantyData : []}
                    columns={superadmin ? hqColumns : columns}
                    pageCount={Math.floor(warranties.totalPages / 20) + 2}
                    totalCount={warranties.totalPages}
                    updatePagination={setInputs}
                    paginationState={inputs}
                    updateLoaded={setIsLoaded}
                    hidePagination={inputs.limit === 20 ? false : true}
                />
            )}
            {returnWarrantyMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setReturnWarrantyMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <div className="paymentTypeContainer">
                                    <div className="remarksContainer">
                                        <label className="block px-2 mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Remarks
                                        </label>
                                        <textarea
                                            className="block p-4 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            name="remarks"
                                            type="textarea"
                                            rows="3"
                                            cols="100"
                                            onChange={(e) => {
                                                setRemarks(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="buttonContainer">
                                    <button
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleReturn({
                                                id: modalData,
                                                remarks,
                                            });
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setReturnWarrantyMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {deleteModalState && (
                <div className="overlayContainer">
                    <div className="absolute top-40 left-1/2 transform -translate-x-1/2 panel bg-white rounded-sm max-w-screen-md px-4"></div>
                    <div className="container h-full mx-auto flex justify-center items-center">
                        <div className="panel bg-white m-12 rounded-md p-16">
                            <div className="mb-6 text-2xl font-bold text-black text-center">
                                Confirm Delete Warranty?
                            </div>
                            <div className="flex justify-between items-center text-center">
                                <div
                                    className="border-solid border-black mr-6 border w-full p-2 cursor-pointer text-icongreen bg-specialgreen hover:bg-icongreen hover:text-white"
                                    onClick={async () => {
                                        try {
                                            await handleDelete(modalData);
                                            setDeleteModalState(false);
                                        } catch (err) {
                                            alert("Failed");
                                            console.log(err);
                                        }
                                    }}
                                >
                                    Yes
                                </div>
                                <div
                                    className="border-solid border-black border w-full p-2 cursor-pointer text-iconpink bg-specialpink hover:bg-iconpink hover:text-white"
                                    onClick={() => setDeleteModalState(false)}
                                >
                                    No
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {completedWarrantyMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setReturnWarrantyMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <div className="summary">
                                    <div className="addDiscountItem mx-16 my-8">
                                        <div className="summarysubtotal">
                                            Service Price
                                        </div>
                                        <input
                                            name="discount"
                                            type="number"
                                            step="0.01"
                                            className="p-2"
                                            onChange={(e) => {
                                                setServicePrice(e.target.value);
                                            }}
                                            defaultValue={0}
                                        />
                                    </div>
                                </div>
                                <div className="buttonContainer">
                                    <div
                                        className="confirm pointer green"
                                        onClick={() => {
                                            handleComplete({
                                                id: modalData,
                                                servicePrice: servicePrice,
                                            });
                                        }}
                                    >
                                        Confirm
                                    </div>
                                    <button
                                        className="cancel pointer red"
                                        onClick={() => {
                                            setReturnWarrantyMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
