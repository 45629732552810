import "./orderList.css";
import Table from "../../components/advancedTable";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,
    VerifiedUser,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
    voidCredit,
    getSuppliers,
    acceptCredit,
    getAllSupplier,
} from "../../redux/apiCalls";
import moment from "moment";
import createBulk from "../../components/pdf/createBulk";

import { publicRequest } from "../../requestMethods";
import createCreditInvoice from "../../components/pdf/createCreditInvoice";
import excel from "../../components/excel/excel";
import { useLocation } from "react-router";

const statusOptions = [
    {
        label: "Deposit",
        value: "deposit",
    },
    {
        label: "Pending",
        value: "pending",
    },
    {
        label: "Completed",
        value: "completed",
    },
    {
        label: "Void",
        value: "void",
    },
    {
        label: "Warranty",
        value: "warranty",
    },
    {
        label: "All",
        value: "",
    },
];

const limitOptions = [
    {
        label: "Basic",
        value: 20,
    },
    {
        label: "Export",
        value: 9999,
    },
];

export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const dispatch = useDispatch();
    const location = useLocation();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const admin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isAdmin : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [inputs, setInputs] = useState({
        createdBy: manager
            ? principle
            : staff
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: undefined,
        limit: 9999,
        page: 1,
        dateFrom: moment(Date.now()).startOf("month").format("YYYY-MM-DD"),
        dateTo: moment(Date.now()).add(1, "days").format("YYYY-MM-DD"),
        user: location
            ? location.state
                ? location.state.outlet
                : undefined
            : undefined,
        type: "supplier",
    });

    const getAdmins = async () => {
        const res = await publicRequest.get("/users/admin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const getAllAdmins = async () => {
        const res = await publicRequest.get("/users/allsuppliers", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    const [statusMenu, setStatusMenu] = useState(false);
    const [ordersData, setOrdersData] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [acceptMenu, setAcceptMenu] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [date, setDate] = useState(undefined);
    const [payloadpaymentType, setPayloadPaymentType] = useState(undefined);
    const [bankInvoice, setBankInvoice] = useState("");
    const [invoice, setInvoice] = useState(undefined);
    const [billNumber, setBillNumber] = useState(undefined);

    // eslint-disable-next-line
    const [paymentType, setPaymentType] = useState([
        {
            label: "Cash",
            value: "cash",
        },
        { value: "onlineTransfer", label: "Online Transfer" },
        { value: "tng", label: "Touch N Go" },
        { value: "cheque", label: "Cheque" },
        { value: "card", label: "Debit Card" },
        { value: "creditCard", label: "Credit Card" },
        { value: "ziip6", label: "ZIIP - 6 months" },
        { value: "ziip12", label: "ZIIP - 12 months" },
        { value: "ziip18", label: "ZIIP - 18 months" },
        { value: "ziip24", label: "ZIIP - 24 months" },
        { value: "others", label: "Others" },
    ]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSerialChange(serialNumber);
        }, 1500);

        return () => {
            clearTimeout(timer);
        };
    }, [serialNumber]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getSuppliers(dispatch, inputs);
                setOrdersData(result);
                setIsLoaded(true);
            } catch (error) {
                console.error("Error fetching orders:", error);
            }
        };

        if (!isLoaded) {
            fetchData();
        }
    }, [dispatch, inputs, isLoaded]);

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            if (process.env.REACT_APP_COUNTRY === "SINGAPORE") {
                getAdmins();
            } else {
                getAllAdmins();
            }
        }
    }, [usersData]);

    const handlePaymentType = (e) => {
        setPayloadPaymentType(e.value);
    };

    const handleDate = (e) => {
        setDate(moment(e.target.value).format("YYYY-MM-DD[T]HH:mm:ss.SSSZ"));
    };

    const handleBankInvoice = (e) => {
        setBankInvoice(e.target.value);
    };

    const handleVoid = (id) => {
        voidCredit(id, dispatch);
    };

    const handleInputUser = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, user: e.value };
        });
    };

    const handleAccept = (id) => {
        acceptCredit(id, { payloadpaymentType, bankInvoice, date }, dispatch);
    };

    const handleInputStatus = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, status: e.value };
        });
    };

    const handleDateFrom = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateFrom: e.target.value };
        });
    };

    const handleSerialChange = (e) => {
        setInputs((prev) => {
            return { ...prev, serialNumber: e };
        });
        setIsLoaded(false);
        setSerialNumber(e);
    };

    const handleDateTo = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, dateTo: e.target.value };
        });
    };

    const handleLimitChange = (e) => {
        setIsLoaded(false);
        setInputs((prev) => {
            return { ...prev, limit: e.value };
        });
    };

    const handleInvoiceChange = (e) => {
        setInputs((prev) => {
            return { ...prev, invoice: e };
        });
        setIsLoaded(false);
    };

    const handleBillNumberChange = (e) => {
        setInputs((prev) => {
            return { ...prev, billNumber: e };
        });
        setIsLoaded(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleBillNumberChange(billNumber);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [billNumber]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleInvoiceChange(invoice);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [invoice]);

    const handleDelayedInvoiceChange = (e) => {
        setInvoice(e.target.value);
    };

    const handleDelayedBillNumberChange = (e) => {
        setBillNumber(e.target.value);
    };

    const columns = [
        {
            accessor: "action",
            Header: "Action",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon mr-2"
                                onClick={() => {
                                    setModalData(params.row.original._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                        {params.row.original.status === "pending" && (
                            <VerifiedUser
                                className=" pointer icon mr-2"
                                onClick={() => {
                                    setModalData(params.row.original._id);
                                    setAcceptMenu(true);
                                }}
                            />
                        )}
                        <CloudDownload
                            className="pointer icon mr-2"
                            onClick={() => {
                                createCreditInvoice(params.row.original);
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "createdAt",
            Header: "Purchase Date",
            sortable: false,
            Cell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            accessor: "product",
            Header: "Products",
            sortable: false,
            Cell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon"
                            onClick={() => {
                                setModalData(params.row.original.products);
                                setStatusMenu(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            accessor: "invoiceId",
            Header: "Invoice",
            sortable: false,
            Cell: ({ value }) => {
                return <div className="initialPadding">{value}</div>;
            },
        },
        {
            accessor: "bankInvoice",
            Header: "Bill Number",
            sortable: false,
            Cell: ({ value }) => {
                return <div className="initialPadding">{value}</div>;
            },
        },
        {
            accessor: "y",
            Header: "Model",
            sortable: false,
            Cell: (params) => {
                return (
                    <div className="initialPadding">
                        {params &&
                        params.row &&
                        params.row.original &&
                        params.row.original.products &&
                        params.row.original.products[0]
                            ? params.row.original.products[0].code
                            : ""}{" "}
                    </div>
                );
            },
        },
        {
            accessor: "userId",
            Header: "Customer",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },
        {
            accessor: "products",
            Header: "Total",
            sortable: false,
            Cell: (params) => {
                let total = 0;
                if (params.row.original.products !== undefined) {
                    for (const product of params.row.original.products) {
                        total = total + product.price * product.amount;
                    }
                }
                return (
                    <div className="capitalize">
                        {params
                            ? params.row.original.country === "singapore"
                                ? "$"
                                : "RM"
                            : "RM"}
                        {formatter.format(
                            params.row.original.deposit &&
                                params.row.original.status === "deposit"
                                ? params.row.original.deposit
                                : params.row.original.deposit &&
                                  params.row.original.status !== "deposit"
                                ? total - params.row.original.discount
                                : total - params.row.original.discount
                        )}
                    </div>
                );
            },
        },
        {
            accessor: "paymentType",
            Header: "Payment Type",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
        {
            accessor: "status",
            Header: "Status",
            sortable: false,
            Cell: ({ value }) => <div className="capitalize">{value}</div>,
        },
    ];

    const productColumns = [
        {
            accessor: "code",
            Header: "Name",
            sortable: false,
            Cell: ({ value }) => <div className="initialPadding">{value}</div>,
        },
        {
            accessor: "quantity",
            Header: "Quantity",
            sortable: false,
            Cell: ({ value }) => <div className="initialPadding">{value}</div>,
        },
        {
            accessor: "price",
            Header: "Unit Price",
            sortable: false,
            Cell: ({ value }) => (
                <div className="initialPadding">
                    RM {value ? formatter.format(value) : "0"}
                </div>
            ),
        },
        {
            accessor: "amount",
            Header: "Total Price",
            sortable: false,
            Cell: (params) => (
                <div className="initialPadding">
                    RM{" "}
                    {params.row.original.amount
                        ? formatter.format(
                              params.row.original.amount *
                                  params.row.original.price
                          )
                        : "0"}
                </div>
            ),
        },
    ];

    return (
        <>
            {!isLoaded ? (
                <></>
            ) : (
                <>
                    <div className="productList">
                        <div className="samerow">
                            <div className="addProductItem">
                                <input
                                    className="inputFilter"
                                    name="dateFrom"
                                    value={inputs.dateFrom}
                                    type="date"
                                    onChange={(e) => handleDateFrom(e)}
                                />
                            </div>
                            <div className="addProductItem ">
                                <input
                                    className="inputFilter"
                                    name="dateTo"
                                    value={inputs.dateTo}
                                    type="date"
                                    onChange={(e) => handleDateTo(e)}
                                />
                            </div>
                            <div className="sameRowItem marginRight">
                                <Select
                                    options={usersData.map((status, i) => {
                                        return {
                                            label: `${status.name}-${status.phoneNumber}`,
                                            value: status._id,
                                        };
                                    })}
                                    onChange={handleInputUser}
                                    placeholder="Supplier"
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? getAllSupplier()
                                            : {}
                                    }
                                />
                            </div>
                            <div className="sameRowItem marginRight">
                                <Select
                                    options={statusOptions.map((status, i) => {
                                        return {
                                            label: status.label,
                                            value: status.value,
                                        };
                                    })}
                                    onChange={handleInputStatus}
                                    placeholder="Status"
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? getAdmins()
                                            : {}
                                    }
                                />
                            </div>
                            <div className="sameRowItem marginRight">
                                {admin && !staff && (
                                    <Select
                                        options={limitOptions.map(
                                            (status, i) => {
                                                return {
                                                    label: `${status.label}`,
                                                    value: status.value,
                                                };
                                            }
                                        )}
                                        onChange={handleLimitChange}
                                        placeholder={"Format"}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="samerow">
                            <div className="addProductItem ">
                                <input
                                    className="inputFilter"
                                    name="invoice"
                                    value={invoice}
                                    type="string"
                                    placeholder="Invoice"
                                    onChange={handleDelayedInvoiceChange}
                                />
                            </div>
                            <div className="addProductItem ">
                                <input
                                    className="inputFilter"
                                    name="billNumber"
                                    value={billNumber}
                                    type="string"
                                    placeholder="Bill Number"
                                    onChange={handleDelayedBillNumberChange}
                                />
                            </div>
                        </div>

                        <div className="block">
                            {superadmin && (
                                <button
                                    onClick={() => {
                                        let payload = [];
                                        ordersData.payload.forEach(
                                            (order, i) => {
                                                let productCodes =
                                                    order.products
                                                        .map(
                                                            (product) =>
                                                                `${product.quantity} x ${product.code}`
                                                        )
                                                        .join(", ");
                                                payload.push({
                                                    No: i + 1,
                                                    Customer:
                                                        order.createdBy.name,
                                                    SKU: productCodes,
                                                    Retail: `${order.amount}`,
                                                    Discount: `${order.discount}`,
                                                    Sales: ` ${
                                                        order.amount -
                                                        order.discount -
                                                        (order.deposit
                                                            ? order.deposit
                                                            : 0)
                                                    }`,
                                                    Deposit: `${
                                                        order.deposit
                                                            ? order.deposit
                                                            : 0
                                                    }`,
                                                    Outlet: order.userId.name,
                                                    Cashier: order.cashier.name,
                                                    PurchaseDate: moment(
                                                        order.createdAt
                                                    ).format("YYYY-MM-DD"),
                                                });
                                            }
                                        );
                                        excel({
                                            excelData: payload,
                                            fileName: `SSR-Orders-${moment(
                                                inputs.dateFrom
                                            ).format("DD-MM-YYYY")}-to-${moment(
                                                inputs.dateTo
                                            ).format("DD-MM-YYYY")}`,
                                        });
                                    }}
                                    className="red buttonTop productListButton"
                                >
                                    Export
                                </button>
                            )}

                            {inputs.status === "pending" &&
                                inputs.user !== undefined && (
                                    <button
                                        onClick={() => {
                                            const mergeItems = (items) => {
                                                // Initialize the merged result object
                                                const merged = {
                                                    address: "-",
                                                    amount: 0,
                                                    discount: 0,
                                                    products: [],
                                                    invoiceIds: [],
                                                    cashier: items[0].cashier, // Assuming cashier is the same for all items
                                                    createdAt:
                                                        new Date().toISOString(),
                                                    createdBy:
                                                        items[0].createdBy, // Assuming createdBy is the same for all items
                                                    paymentType:
                                                        items[0].paymentType, // Assuming paymentType is the same for all items
                                                    salesType:
                                                        items[0].salesType, // Assuming salesType is the same for all items
                                                    status: items[0].status, // Assuming status is the same for all items
                                                    updatedAt:
                                                        new Date().toISOString(),
                                                    userId: items[0].userId, // Assuming userId is the same for all items
                                                    __v: 0,
                                                    _id: `merged_${Date.now()}`, // Generating a new ID for the merged item
                                                };

                                                // Loop through each item and merge the fields
                                                items.forEach(
                                                    ({
                                                        amount,
                                                        discount,
                                                        products,
                                                        createdAt,
                                                        invoiceId,
                                                        plate,
                                                    }) => {
                                                        merged.amount += amount;
                                                        merged.discount +=
                                                            discount;
                                                        products.forEach(
                                                            (product) => {
                                                                product.productdate =
                                                                    moment(
                                                                        createdAt
                                                                    ).format(
                                                                        "YYYY-MM-DD"
                                                                    );
                                                                product.invoiceId =
                                                                    invoiceId;
                                                                product.plate =
                                                                    plate;
                                                            }
                                                        );

                                                        merged.products = [
                                                            ...merged.products,
                                                            ...products,
                                                        ];
                                                    }
                                                );

                                                return merged;
                                            };

                                            const mergedItem = mergeItems(
                                                ordersData.payload
                                            );

                                            createBulk(mergedItem);
                                        }}
                                        className="blue buttonTop productListButton"
                                    >
                                        Supplier Statement
                                    </button>
                                )}
                        </div>
                        {isLoaded === true && (
                            <Table
                                data={ordersData.payload}
                                columns={columns}
                                pageCount={
                                    ordersData
                                        ? Math.floor(
                                              ordersData.totalPages / 20
                                          ) + 2
                                        : 1
                                }
                                totalCount={ordersData.totalPages}
                                updatePagination={setInputs}
                                paginationState={inputs}
                                updateLoaded={setIsLoaded}
                            />
                        )}
                        <div className="reportContainer">
                            <div className="featuredReport blue">
                                <span className="featuredTitle">
                                    Total Orders:
                                </span>
                                <div className="featuredReportContainer">
                                    <span className="featuredMoney">
                                        {" "}
                                        {ordersData.totalPages}
                                    </span>
                                </div>
                            </div>

                            <div className="featuredReport red">
                                <span className="featuredTitle">
                                    Total Amount:
                                </span>
                                <div className="featuredReportContainer">
                                    <span className="featuredMoney">
                                        {" "}
                                        RM{" "}
                                        {formatter.format(
                                            ordersData.totalAmount
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {statusMenu && (
                        <div className="overlayContainer">
                            <div className="bigcenter">
                                <div
                                    className="close"
                                    onClick={() => setStatusMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="updatemodalContainer">
                                    <div className="updateOrder">
                                        <h1 className="updateProductTitle text-xl font-semibold">
                                            Products({modalData.length})
                                        </h1>
                                        <Table
                                            data={modalData}
                                            columns={productColumns}
                                            hidePagination={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {voidMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setVoidMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="actionContainer">
                                    <div className="updateOrder">
                                        <h3 className="updateProductTitle">
                                            Confirm Void Order?
                                        </h3>
                                        <div className="buttonContainer">
                                            <button
                                                className="confirm pointer green"
                                                onClick={() => {
                                                    handleVoid(modalData);
                                                    setVoidMenu(false);
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="cancel pointer red"
                                                onClick={() => {
                                                    setVoidMenu(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {acceptMenu && (
                        <div className="overlayContainer">
                            <div className="center">
                                <div
                                    className="close"
                                    onClick={() => setAcceptMenu(false)}
                                >
                                    <Close></Close>
                                </div>
                                <div className="actionContainer">
                                    <div className="updateOrder">
                                        <h3 className="updateProductTitle">
                                            Confirm Accept Order?
                                        </h3>
                                        <div className="mb-2">
                                            <label className="flex justify-start text-lynch">
                                                Bill Number
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full p-2"
                                                onChange={handleBankInvoice}
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="flex justify-start text-lynch">
                                                Payment Type
                                            </label>
                                            <Select
                                                options={paymentType.map(
                                                    (paymentType, i) => {
                                                        return {
                                                            label: paymentType.label,
                                                            value: paymentType.value,
                                                        };
                                                    }
                                                )}
                                                onChange={handlePaymentType}
                                            />
                                        </div>

                                        <div className="mb-2">
                                            <label className="flex justify-start text-lynch">
                                                Date
                                            </label>
                                            <input
                                                type="date"
                                                className="w-full p-2"
                                                onChange={handleDate}
                                            />
                                        </div>

                                        <div className="buttonContainer">
                                            <button
                                                className="confirm pointer green"
                                                onClick={() => {
                                                    handleAccept(modalData);
                                                    setAcceptMenu(false);
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                className="cancel pointer red"
                                                onClick={() => {
                                                    setAcceptMenu(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
