import { useState, useEffect } from "react";
import { newVehicle } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";


export default function NewVehicle() {
    const [inputs, setInputs] = useState({});
    const [usersData, setUsersData] = useState([]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData, dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleClick = async (e) => {
        const vehicle = { ...inputs };
        e.preventDefault();
        try {
            newVehicle({ vehicle });
        } catch (err) {
            console.log(err);
        }
    }

    const getUsers = async () => {
        const res = await publicRequest.get("/users/all", {
            params: {},
        });
        setUsersData(res.data);
    };

    const handleInputUserId = (e) => {
        setInputs((prev) => {
            return { ...prev, user: e.value };
        });
    };

    return (
        <>
            <div className="newProduct">
                <h1 className="addProductTitle">New Vehicle</h1>
                <div className="addProductForm">
                <div className="addProductItem">
                        <label className="black required">Mileage</label>
                        <input
                            name="mileage"
                            type="text"
                            placeholder="Mileage"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Number Plate</label>
                        <input
                            name="plate"
                            type="text"
                            placeholder="Number Plate"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                        <label className="black required">Car Model</label>
                        <input
                            name="car"
                            type="text"
                            placeholder="Car Model"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="addProductItem">
                                <label>Customer</label>
                                <Select
                                    options={usersData.map((user, i) => {
                                        return {
                                            label: `${user.name}-${user.username}-${user.phoneNumber}`,
                                            value: user._id,
                                        };
                                    })}
                                    onChange={handleInputUserId}
                                />
                            </div>
                    <button onClick={handleClick} className="updateProductButton">
                        Create
                    </button>
                </div>
            </div>
        </>
    );
}
