import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "ssruser",
    initialState: {
        currentUser: null,
        isFetching: false,
        error: false,
    },
    reducers: {
        loginStart: (state) => {
            state.isFetching = true;
            state.currentUser = null;
        },
        loginSuccess: (state, action) => {
            const payload = { ...action.payload, loginStamp: new Date() };
            state.isFetching = false;
            state.currentUser = payload;
        },
        loginFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        logout: (state) => {
            state.currentUser = null;
        },
        getUserStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getUserSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        getUserFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const { loginStart, loginSuccess, loginFailure, logout,     getUserStart,
    getUserSuccess,
    getUserFailure, } =
    userSlice.actions;
export default userSlice.reducer;
