import "./depositList.css";
import { DataGrid } from "@material-ui/data-grid";
import {
    Close,
    LocalGroceryStore,
    CloudDownload,
    VerifiedUser,
    AddAlert
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, voidOrder } from "../../redux/apiCalls";
import moment from "moment";
import { userRequest } from "../../requestMethods";
import createCustomerInvoice from "../../components/pdf/createCustomerInvoice";

export default function WarrantyList() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();

    const staff = useSelector((state) =>
    state.user.currentUser ? state.user.currentUser.type === "staff" : null
);

    const currentUser = useSelector((state) => state.user.currentUser);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );

    const [inputs, setInputs] = useState({
        createdBy: manager
            ? principle
            : staff
            ? principle
            : currentUser
            ? currentUser._id
            : undefined,
        status: "deposit",
        limit: 20,
        page: 1,
    });
    const [statusMenu, setStatusMenu] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [voidMenu, setVoidMenu] = useState(false);
    const [fullpaymentMenu, setFullPaymentMenu] = useState(false);
    const [installmentMenu, setInstallmentMenu] = useState(false);
    const [paymentType, setPaymentType] = useState("others");
    const [amount, setAmount] = useState(0);
    const [modalData, setModalData] = useState([]);
    const [ordersData, setOrdersData] = useState(false);
    const [remainingBalance, setRemainingBalance] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await getOrders(dispatch, inputs);
            if (res) {
                setIsLoaded(true);
                setOrdersData(res);
            }
        };
        fetchData();
    }, [dispatch, inputs]);

    useEffect(() => {
        if (statusMenu === true) {
            document.body.style.overflow = "hidden";
            window.scrollTo(0, 0);
        } else {
            document.body.style.overflow = "inherit";
        }
    }, [statusMenu]);

    const fetchOrders = async (dispatch, inputs) => {
        await getOrders(dispatch, inputs);
    };

    const handleVoid = (id) => {
        voidOrder(id, dispatch);
    };

    const handleFullPayment = async (id) => {


        if (currentUser) {
            try {
                const res = await userRequest.post("/orders/fullPayment", {
                    orderId: id,
                    paymentType,
                });

                if (res.data) {
                    alert("Full payment has been made for deposit");
                    createCustomerInvoice(res.data)
                    setFullPaymentMenu(false);
                } else {
                    alert("Server issue, please contact support");
                    setFullPaymentMenu(false);
                }
            } catch (err) {
                alert(
                    "Failed to accept full payment, please check if warranty is valid"
                );
                setFullPaymentMenu(false);
            }
        } else {
            alert("Please login before checking out");
            setFullPaymentMenu(false);
        }
    };

    const handleChange = (e) => {
        setPaymentType(e.target.value);
    };

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
    };

    const handleInstallment = async (id) => {
        if (currentUser) {
            try {
                const res = await userRequest.post("/installment", {
                    order: id,
                    paymentType: paymentType,
                    amount: amount
                });

                if (res.data) {
                    alert("Installment created successfully.");
                    createCustomerInvoice(res.data)
                    setFullPaymentMenu(false);
                } else {
                    alert("Server issue, please contact support");
                    setFullPaymentMenu(false);
                }
            } catch (err) {
                alert(
                    "Please make sure remaining balance is less than amount, and use full payment for full payment."
                );
                setFullPaymentMenu(false);
            }
        } else {
            alert("Please login before checking out");
            setFullPaymentMenu(false);
        }
    };


    const columns = [
        {
            field: "action",
            headerName: "Action",
            width: 250,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <VerifiedUser
                            className="pointer mr-2"
                            onClick={() => {
                                const original = params.row;
                                const remaining =
                                    original.amount -
                                    original.deposit -
                                    original.discount;
                                setModalData(original._id);
                                setRemainingBalance(remaining);
                                setFullPaymentMenu(true);
                            }}
                        />
                        {!accountant && (
                            <Close
                                className="productListDelete pointer icon mr-2"
                                onClick={() => {
                                    setModalData(params.row._id);
                                    setVoidMenu(true);
                                }}
                            />
                        )}
                        <CloudDownload
                            className="pointer icon mr-2"
                            onClick={() => {
                                createCustomerInvoice(params.row);
                            }}
                        />
                                                <AddAlert
                            className="pointer"
                            onClick={() => {
                                const original = params.row;
                                const remaining =
                                    original.amount -
                                    original.deposit -
                                    original.discount;
                                setModalData(original._id);
                                setRemainingBalance(remaining);
                                setInstallmentMenu(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            field: "product",
            headerName: "Products",
            width: 120,
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <LocalGroceryStore
                            className="icon"
                            onClick={() => {
                                setModalData(params.row.products);
                                setStatusMenu(true);
                            }}
                        />
                    </>
                );
            },
        },
        {
            field: "invoiceId",
            headerName: "Invoice",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "userId",
            headerName: "Customer",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value.name : ""}</div>
            ),
        },
        {
            field: "total",
            headerName: "Total Price",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                let total = 0;
                if (params.row.products !== undefined) {
                    for (const product of params.row.products) {
                        total = total + product.price;
                    }
                }
                return (
                    <div className="capitalize">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}{" "}
                        {formatter.format(total)}
                    </div>
                );
            },
        },
        {
            field: "discount",
            headerName: "Discount",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <div className="capitalize">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}{" "}
                        {value ? formatter.format(value) : 0}
                    </div>
                );
            },
        },
        {
            field: "deposit",
            headerName: "Deposit",
            width: 150,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <div className="capitalize">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}{" "}
                        {value ? formatter.format(value) : 0}
                    </div>
                );
            },
        },
        {
            field: "products",
            headerName: "Remaining",
            width: 150,
            sortable: false,
            renderCell: (params) => {
                let total = 0;
                if (params.row.products !== undefined) {
                    for (const product of params.row.products) {
                        total = total + product.price;
                    }
                }
                const remaining =
                params.row.discount ?
                    total - params.row.discount - params.row.deposit : total - params.row.deposit;
                return (
                    <div className="capitalize">
                        {process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"}{" "}
                        {formatter.format(remaining)}
                    </div>
                );
            },
        },
        {
            field: "depositDate",
            headerName: "Deposit Date",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        {
            field: "depositType",
            headerName: "Deposit Type",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value}</div>
            ),
        },
 
    ];

    return (
        <>
            {" "}
            {isLoaded === true && (
                <div className="productList">
                    <DataGrid
                        rows={
                            ordersData ? ordersData.payload : []
                        }
                        disableSelectionOnClick
                        columns={columns}
                        getRowId={(row) => (row ? row._id : {})}
                        pageSize={20}
                        rowCount={ordersData.totalPages}
                        disableColumnMenu
                        paginationMode="server"
                        onPageChange={async (newPage) => {
                            inputs.page = newPage.page;
                            await fetchOrders(dispatch, inputs);
                            setInputs((prev) => ({
                                ...prev,
                                page: newPage.page,
                            }));
                        }}
                    />
                </div>
            )}
            {statusMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setStatusMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="updatemodalContainer">
                            <div className="updateOrder">
                                <h1 className="updateProductTitle">
                                    Products({modalData.length})
                                </h1>
                                <div className="productContainer">
                                            {modalData.map((product) => {
                                                return (
                                                    <div
                                                        className="product"
                                                        key={product}
                                                    >
                                                        {" "}
                                                        <div className="justify-center flex mb-2">
                                                            <img
                                                                src={
                                                                    product.img
                                                                }
                                                                className="productImg"
                                                                alt="productImages"
                                                            />
                                                        </div>
                                                        <div className="productDetails">
                                                            {product.quantity} x{" "}
                                                            {product.code}
                                                        </div>
                                                        <div className="productDetails">
                                                            {product.quantity} x
                                                            RM
                                                            {formatter.format([
                                                                product.price,
                                                            ])}
                                                        </div>
                                                        <div className="productDetails">
                                                            RM{" "}
                                                            {formatter.format([
                                                                product.quantity *
                                                                    product.price,
                                                            ])}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {voidMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setVoidMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Void Order?
                                </h3>
                                <div className="buttonContainer">
                                    <button
                                                                              className="confirm pointer green"
                                        onClick={() => {
                                            handleVoid(modalData);
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                      className="cancel pointer red"
                                        onClick={() => {
                                            setVoidMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {fullpaymentMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setFullPaymentMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Confirm Full Payment?
                                </h3>
                                <h3 className="updateProductTitle">
                                    Remaining Balance :{" "}
                                    {process.env.REACT_APP_COUNTRY ===
                                    "SINGAPORE"
                                        ? "$"
                                        : "RM"}{" "}
                                    {formatter.format(remainingBalance)}
                                </h3>
                                <div className="paymentTypeContainer">
                                    <div className="addProductItem">
                                        <label>Payment Type</label>
                                        <select
                                            name="paymentType"
                                            id="paymentType"
                                            onChange={handleChange}
                                        >
                                            <option value="cash">Cash</option>
                                            <option value="card">
                                                Debit Card
                                            </option>
                                            <option value="creditCard">
                                                Credit Card
                                            </option>
                                            <option value="ziip6">
                                                ZIIP - 6 months
                                            </option>
                                            <option value="ziip12">
                                                ZIIP - 12 months
                                            </option>
                                            <option value="ziip18">
                                                ZIIP - 18 months
                                            </option>
                                            <option value="ziip24">
                                                ZIIP - 24 months
                                            </option>
                                            <option value="onlineTransfer">
                                                Online Transfer
                                            </option>
                                            <option value="tng">
                                                Touch N Go
                                            </option>
                                            <option value="cheque">
                                                Cheque
                                            </option>
                                            <option value="others">
                                                Others
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="buttonContainer">
                                    <button
                                                                              className="confirm pointer green"
                                        onClick={() => {
                                            handleFullPayment(modalData);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                      className="cancel pointer red"
                                        onClick={() => {
                                            setFullPaymentMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

{installmentMenu && (
                <div className="overlayContainer">
                    <div className="center">
                        <div
                            className="close"
                            onClick={() => setInstallmentMenu(false)}
                        >
                            <Close></Close>
                        </div>
                        <div className="actionContainer">
                            <div className="updateOrder">
                                <h3 className="updateProductTitle">
                                    Add Installment
                                </h3>
                                <h3 className="updateProductTitle">
                                    Remaining Balance :{" "}
                                    {process.env.REACT_APP_COUNTRY ===
                                    "SINGAPORE"
                                        ? "$"
                                        : "RM"}{" "}
                                    {formatter.format(remainingBalance)}
                                </h3>
                                <div className="paymentTypeContainer">
                                <div className="addProductItem">
                        <label className="">Amount (RM)</label>
                        <input
                            name="amount"
                            type="number"
                            placeholder="Amount"
                            onChange={handleChangeAmount}
                        />
                    </div>
                                    <div className="addProductItem">
                                        <label>Payment Type</label>
                                        <select
                                            name="paymentType"
                                            id="paymentType"
                                            onChange={handleChange}
                                        >
                                            <option value="cash">Cash</option>
                                            <option value="card">
                                                Debit / Credit Card
                                            </option>
                                            <option value="onlineTransfer">
                                                Online Transfer
                                            </option>
                                            <option value="tng">
                                                Touch N Go
                                            </option>
                                            <option value="cheque">
                                                Cheque
                                            </option>
                                            <option value="others">
                                                Others
                                            </option>
                                        </select>
                                    </div>
                                    
                                </div>
                                <div className="buttonContainer">
                                    <button
                                                                              className="confirm pointer green"
                                        onClick={() => {
                                            handleInstallment(modalData);
                                        }}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                      className="cancel pointer red"
                                        onClick={() => {
                                            setInstallmentMenu(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
