import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";
import _ from "lodash";

const createPdf = async (original) => {
    try {
        let addressArray = [];

        for (let i = 0; i < original.address.length; i += 80) {
            addressArray.push(original.address.substring(i, i + 80));
        }

        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        // Recompile product array with quantity based on each product model
        const finalArray = original.products;

        const transformed = Object.entries(finalArray).map(
            ([code, product]) => ({
                code: product.code,
                quantity: product.quantity,
                title: product.title,
                price: product.price,
            })
        );
        

        // Add a blank page to the document
        const page = pdfDoc.addPage();
        const fontSize = 20;
        let totalAmount = 0;

        for (const product of original.products) {
                         totalAmount = totalAmount + product.price;
        }

        // Get the width and height of the page
        const { height } = page.getSize();

        const jpgUrl =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/Haofa_Signoff_SG_1200px_whp1QLEzE.jpg?updatedAt=1693212751559"
                : original.createdBy.address.city === "Johor Bahru"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/jbreceipt_uYJMyCMv_.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1678290172244"
                : "https://ik.imagekit.io/dnddecpho/Haofa/receipt__jfAFa9wP.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1671007440100";
        const jpgImageBytes = await fetch(jpgUrl).then((res) =>
            res.arrayBuffer()
        );
        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
        const jpgDims = jpgImage.scale(0.5);
        page.drawImage(jpgImage, {
            x: 0,
            y: height - 6 * fontSize,
            width: jpgDims.width,
            height: jpgDims.height,
        });

        page.drawText(`RECEIPT`, {
            x: 255,
            y: height - 7.8 * fontSize,
            size: 20,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Invoice ID:`, {
            x: 60,
            y: height - 9.6 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${_.padStart(original.invoiceId, 4, "0")}`, {
            x: 180,
            y: height - 9.6 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Date of Issue:`, {
            x: 60,
            y: height - 10.3 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${moment(original.createdAt).format("DD MMM YYYY")}`, {
            x: 180,
            y: height - 10.3 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Outlet Name:`, {
            x: 60,
            y: height - 11 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.createdBy.name}`, {
            x: 180,
            y: height - 11 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Outlet Contact:`, {
            x: 60,
            y: height - 11.7 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.createdBy.phoneNumber}`, {
            x: 180,
            y: height - 11.7 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Outlet Address:`, {
            x: 60,
            y: height - 12.4 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(
            `${original.createdBy.address.lineOne}${original.createdBy.address.lineTwo}`,
            {
                x: 180,
                y: height - 12.4 * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            }
        );

        page.drawText(
            `${original.createdBy.address.zip}, ${original.createdBy.address.city}, ${original.createdBy.address.country}`,
            {
                x: 180,
                y: height - 13.1 * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            }
        );

        page.drawText(`Customer:`, {
            x: 60,
            y: height - 14.5 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.userId.name}`, {
            x: 180,
            y: height - 14.5 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Email:`, {
            x: 60,
            y: height - 15.2 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.userId.email}`, {
            x: 180,
            y: height - 15.2 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Phone Number:`, {
            x: 60,
            y: height - 15.9 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${original.userId.phoneNumber}`, {
            x: 180,
            y: height - 15.9 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(`Address:`, {
            x: 60,
            y: height - 16.6 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        addressArray.forEach((address, index) => {
            page.drawText(`${address}`, {
                x: 180,
                y: height - (16.6 + (index - (index * 0.25))) * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            });
        });

        page.drawText("No", {
            x: 50,
            y: height - 19.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Model", {
            x: 90,
            y: height - 19.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText("Price", {
            x: 500,
            y: height - 19.25 * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - 19.75 * fontSize,
            },
            end: {
                x: 560,
                y: height - 19.75 * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        transformed.forEach((product, index) => {
            page.drawText(`${index + 1}`, {
                x: 50,
                y: height - (21 + index) * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
                weight: 600,
            });

            page.drawText(`${product.quantity} x ${product.code}`, {
                x: 90,
                y: height - (21 + index) * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            });


            page.drawText(
                `${
                    process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"
                } ${NumberFormatter.format(product.price)}`,
                {
                    x: 500,
                    y: height - (21 + index) * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                }
            );
        });

        page.drawLine({
            start: {
                x: 30,
                y: height - (23 + transformed.length) * fontSize,
            },
            end: {
                x: 560,
                y: height - (23 + transformed.length) * fontSize,
            },
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });

        page.drawText(`SUB TOTAL:`, {
            x: 300,
            y: height - (24 + transformed.length) * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(
            `${
                process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"
            } ${NumberFormatter.format(totalAmount)}`,
            {
                x: 500,
                y: height - (24 + transformed.length) * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            }
        );

        page.drawText(`TOTAL:`, {
            x: 300,
            y: height - (26 + transformed.length) * fontSize,
            size: 11,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
        });

        page.drawText(
            `${
                process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"
            } ${NumberFormatter.format(totalAmount)}`,
            {
                x: 500,
                y: height - (26 + transformed.length) * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            }
        );

        if (original.deposit !== 0 && original.status === "deposit") {
            page.drawText(`DEPOSIT:`, {
                x: 300,
                y: height - (27 + transformed.length) * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            });

            page.drawText(
                `${
                    process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"
                } ${NumberFormatter.format(original.deposit)}`,
                {
                    x: 500,
                    y: height - (27 + transformed.length) * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                }
            );

            page.drawText(`REMAINING:`, {
                x: 300,
                y: height - (28 + transformed.length) * fontSize,
                size: 11,
                font: timesRomanFont,
                color: rgb(0, 0, 0),
            });

            page.drawText(
                `${
                    process.env.REACT_APP_COUNTRY === "SINGAPORE" ? "$" : "RM"
                } ${NumberFormatter.format(
                    totalAmount - original.discount - original.deposit
                )}`,
                {
                    x: 500,
                    y: height - (28 + transformed.length) * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                }
            );
        }

        const btmBannerUrl =
            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/Haofa_Signoff_SG_1200px_RCwOrSByd.jpg?updatedAt=1702890385105"
                : original.createdBy.address.city === "Johor Bahru"
                ? "https://ik.imagekit.io/dnddecpho/Haofa/jbreceiptBottomBanner_W1gq7vD4-.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1678290172501"
                : "https://ik.imagekit.io/dnddecpho/haofa/Haofa_Signoff_KL2_RPltnPzEh.jpg?updatedAt=1698062422962";
        const btmBannerImageBytes = await fetch(btmBannerUrl).then((res) =>
            res.arrayBuffer()
        );
        const btmBannerImage = await pdfDoc.embedJpg(btmBannerImageBytes);
        const btmBannerDims = btmBannerImage.scale(0.5);
        page.drawImage(btmBannerImage, {
            x: 0,
            y: height - 42.5 * fontSize,
            width: btmBannerDims.width,
            height: btmBannerDims.height,
        });

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(pdfBytes, `haofa-ereceipt-${original._id}`, "application/pdf");
    } catch (err) {
        alert("Failed");
        console.log(err);
    }
};

export default createPdf;
