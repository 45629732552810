import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import createPagination from "../Hooks/usePagination";
import { MdCompareArrows } from "react-icons/md";

let reload = false;
let page = 1;

const Table = ({
    columns,
    data,
    updatePagination,
    paginationState,
    pageCount: controlledPageCount,
    sortable,
    hidePagination,
    updateLoaded,
    total
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        gotoPage,
        pageCount,
        canNextPage,
        canPreviousPage,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            manualPagination: true,
            pageCount: controlledPageCount,
            initialState: {
                pageIndex: 0,
            },
        },
        usePagination
    );

    useEffect(() => {
        if (reload) {
            updatePagination((prev) => ({
                ...prev,
                page: pageIndex,
            }));

            updateLoaded(false);
            reload = false;
        }
    }, [updatePagination, updateLoaded, pageIndex, pageSize]);

    const { pagination } = createPagination({
        numberOfButtons: 2,
        currentPage: paginationState ? paginationState.page : pageIndex + 1,
        totalPages: paginationState
            ? paginationState.totalPages
                ? paginationState.totalPages
                : controlledPageCount
            : controlledPageCount,
    });

    return (
        <div className="table-wrapper block max-w-full overflow-y-hidden ">
            <table className="bg-white w-full" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    className="p-4 bg-gray-800 text-white border-t font-semibold text-xs min-w-ten"
                                    {...column.getHeaderProps()}
                                >
                                    <div
                                        className={`flex items-center justify-center`}
                                    >
                                        <div className="whitespace-no-wrap">
                                            {column.render("Header")}
                                        </div>
                                        {sortable && (
                                            <div className="cursor-pointer ml-2">
                                                {sortable.includes(
                                                    column.id
                                                ) && (
                                                    <MdCompareArrows
                                                        onClick={() => {
                                                            updatePagination(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    by: column.id,
                                                                    dir:
                                                                        prev.dir ===
                                                                        "asc"
                                                                            ? "desc"
                                                                            : "asc",
                                                                    page: 1,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                className={
                                    row.original.status === "cancelled"
                                        ? "bg-red-700 text-white"
                                        : row.original.status === "pending"
                                        ? "bg-yellow-300 text-black"
                                        : row.original.status === "completed"
                                        ? "bg-green-400 text-black"
                                        : row.original.status === "suspended"
                                        ? "bg-red-700 text-white"
                                        : row.original.status === "void"
                                        ? "bg-purple-500 text-white"
                                        : row.original.calculatePoints ===
                                          "void"
                                        ? "bg-red-700 text-white"
                                        : ""
                                }
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            className="text-center p-4 text-sm border-t"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                    {total && (
                                        <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                                                <th
                                                        className="p-4 bg-gray-800 text-white border-t font-semibold text-xs min-w-ten"
                                                    >
                                                        <div
                                                            className={`flex items-center justify-center`}
                                                        >
                                                            <div className="whitespace-no-wrap">
                                                                Total
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="p-4 bg-gray-800 text-white border-t font-semibold text-xs min-w-ten"
                                                    >
                                                        <div
                                                            className={`flex items-center justify-center`}
                                                        >
                                                            <div className="whitespace-no-wrap">
                                                                RM {total[0]}
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="p-4 bg-gray-800 text-white border-t font-semibold text-xs min-w-ten"
                                                    >
                                                        <div
                                                            className={`flex items-center justify-center`}
                                                        >
                                                            <div className="whitespace-no-wrap">
                                                            RM {total[1]}
                                                            </div>
                                                        </div>
                                                    </th>
                                            </tr>
                                        ))}
                                    </thead>
                                    )}
            </table>
            {!hidePagination && (
                <div className="p-4 bg-cool-gray border-t font-semibold text-lynch text-xs">
                    <ul className="flex justify-end pr-5">
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => {
                                page = 1;
                                gotoPage(1);
                                reload = true;
                            }}
                            disabled={!canPreviousPage}
                        >
                            First
                        </li>
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => {
                                gotoPage(pagination[0]);
                                page = page - 1
                                reload = true;
                            }}
                        >
                            Previous
                        </li>
                        {pagination.map((list) => (
                            <li
                                className={`p-2 px-4 cursor-pointer ${
                                    list === page
                                        ? "bg-darkblue text-white"
                                        : "text-xs"
                                }`}
                                key={list}
                                onClick={() => {
                                    gotoPage(list);
                                    reload = true;
                                    page = list
                                }}
                            >
                                {list}
                            </li>
                        ))}
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => {
                                gotoPage(page === 1 ? pagination[1] : pagination[2]);
                                page = page + 1
                                reload = true;
                            }}
                        >
                            Next
                        </li>
                        <li
                            className="p-2 cursor-pointer"
                            onClick={() => {
                                gotoPage(pageCount - 1);
                                page = (pageCount - 1)
                                reload = true;
                            }}
                            disabled={!canNextPage}
                        >
                            Last
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Table;
