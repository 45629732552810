import { createSlice } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

const orderSlice = createSlice({
    name: "ssrorder",
    initialState: {
        order: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        getSalesReportStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSalesReportSuccess: (state, action) => {
            state.isFetching = false;
            state.order = action.payload;
        },
        getSalesReportFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getOrderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getOrderSuccess: (state, action) => {
            state.isFetching = false;
        },
        getOrderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getMallReportStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getToppenReportSuccess: (state, action) => {
            const today = action.payload.slice(11, 21)
            const machineId = action.payload.slice(0, 10)
            function exportFile(data) {
                var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                saveAs(blob, `H${machineId}_${today}.txt`);
              }
            exportFile(action.payload)
            state.isFetching = false;
        },
        getVelocityReportSuccess: (state, action) => {
            const today = action.payload.slice(11, 21)
            const machineId = action.payload.slice(0, 10)
            function exportFile(data) {
                var blob = new Blob([data], { type: "text/plain;charset=utf-8" });
                saveAs(blob, `H${machineId}_${today}.txt`);
              }
            exportFile(action.payload)
            state.isFetching = false;
        },
        getMallReportFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        getReportStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getReportSuccess: (state, action) => {
            state.isFetching = false;
            state.order = action.payload;
        },
        getReportFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getSalesReportStart,
    getSalesReportSuccess,
    getSalesReportFailure,
    getOrderStart,
    getOrderSuccess,
    getOrderFailure,
    getMallReportStart,
    getToppenReportSuccess,
    getVelocityReportSuccess,
    getMallReportFailure,
    getReportStart,
    getReportSuccess,
    getReportFailure,
} = orderSlice.actions;
export default orderSlice.reducer;
