import "./history.css";
import { DataGrid } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getJournalHistory } from "../../redux/apiCalls";
import moment from "moment";
import Select from "react-select";
import { publicRequest } from "../../requestMethods";

export default function DepositHistory() {
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const orders = useSelector((state) => state.order.order);
    const currentUser = useSelector((state) => state.user.currentUser);

    const marketing = useSelector((state) =>
    state.user.currentUser
        ? state.user.currentUser.type === "marketing"
        : null
);

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );

    const accountant = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "accountant"
            : null
    );

    const [inputs, setInputs] = useState({
        createdBy: currentUser ? currentUser._id : undefined,
        user: superadmin || manager || accountant || marketing ? undefined : currentUser._id,
        limit: 20,
        page: 1,
    });
    const [ordersData, setOrdersData] = useState([]);
    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
        async function fetchData() {
          const result = await getJournalHistory(dispatch, inputs);

          setHistoryData(result.payload);
        }
        
        fetchData();
      }, [dispatch, inputs]);
      

    useEffect(() => {
        if (ordersData[0] === undefined) {
            getOrders();
        }
    }, [ordersData]);


    const handleUserFilter = (e) => {
        setInputs((prev) => {
            return { ...prev, order: e.value };
        });
    };

    const fetchOrders = async (dispatch, inputs) => {
        const result = await getJournalHistory(dispatch, inputs);
        setHistoryData(result.payload)
    };

    const getOrders = async () => {
        const res = await publicRequest.get("/orders/all", {
            params: {},
        });
        setOrdersData(res.data);
    };

    const columns = [
        {
            field: "order",
            headerName: "Order",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value.invoiceId : ""}</div>
            ),
        },
        {
            field: "paymentType",
            headerName: "Account Name",
            width: 400,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">{value === "cardcomm" ? "Credit Card - Merchant Commission" : "Public Bank Berhad"}</div>
            ),
        },
        {
            field: "amount",
            headerName: "Amount",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">RM{formatter.format(value)}</div>
            ),
        },
        {
            field: "card",
            headerName: "Details",
            width: 400,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="initialPadding">{value ? value : ""}</div>
            ),
        },
        {
            field: "createdAt",
            headerName: "Date",
            width: 200,
            sortable: false,
            renderCell: ({ value }) => (
                <div className="capitalize">
                    {" "}
                    {moment(value).format("DD/MM/YYYY")}
                </div>
            ),
        },
        
    ];

    return (
        <>
            <div className="productList">
                <div className="addProductItem">
                    <Select
                        options={ordersData.map((user, i) => {
                            return {
                                label: `${user.invoiceId}`,
                                value: user._id,
                            };
                        })}
                        placeholder={"Invoice ID"}
                        onChange={handleUserFilter}
                    />
                </div>
                <DataGrid
                    rows={historyData}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => (row ? row._id : {})}
                    pageSize={20}
                    rowCount={orders.totalPages}
                    disableColumnMenu
                    paginationMode="server"
                    onPageChange={async (newPage) => {
                        inputs.page = newPage.page;
                        await fetchOrders(dispatch, inputs);
                        setInputs((prev) => ({
                            ...prev,
                            page: newPage.page,
                        }));
                    }}
                />
            </div>
        </>
    );
}
