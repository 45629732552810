import { useState, useEffect } from "react";
import "./newWarranty.css";
import { addWarranty, getBrands, getProducts } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

export default function NewWarranty() {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();

    const products = useSelector((state) => state.product.products);
    const brands = useSelector((state) => state.product.brands);
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        getProducts(dispatch, "");
        getBrands(dispatch);
    }, [dispatch]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleProductChange = (e) => {
        setInputs((prev) => {
            return { ...prev, product: e.value };
        });
    };

    const handleBrandChange = (e) => {
        setInputs((prev) => {
            return { ...prev, brand: e.value };
        });
    };

    const handleClick = async () => {
        const warranty = { ...inputs, currentUser: currentUser._id };
        if (warranty.product && warranty.quantity ) {
            addWarranty(warranty, dispatch);
        } else {
            alert("Please fill all the fields!");
        }
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">Add Stock</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Amount</label>
                    <input
                        name="quantity"
                        type="number"
                        placeholder="Quantity"
                        onChange={handleChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Category</label>
                    <Select
                        options={products.map((product, i) => {
                            return { label: product.title, value: product._id };
                        })}
                        onChange={handleProductChange}
                    />
                </div>
                <div className="addProductItem">
                    <label>Brand</label>
                    <Select
                        options={brands.map((product, i) => {
                            return { label: product.name, value: product._id };
                        })}
                        onChange={handleBrandChange}
                    />
                </div>
                <button onClick={handleClick} className="addProductButton">
                    Add Stock
                </button>
            </div>
        </div>
    );
}
