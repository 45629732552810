import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";

const createDeliveryOrderForm = async (original) => {
    try {
        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        const totalPages =
            original.products.length <= 10
                ? 1
                : Math.ceil((original.products.length - 10) / 10 + 1);

        for (let i = 0; i < totalPages; i++) {
            // Add a blank page to the document
            const page = pdfDoc.addPage();
            const fontSize = 20;

            // Get the width and height of the page
            const { height } = page.getSize();

            if (i === 0) {
                const pageData = original.products.slice(i * 10, (i + 1) * 10);
                page.drawText(`SPEED STAR RACING MOTORSPORT`, {
                    x: 60,
                    y: height - 2 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`202403122361 (003600128-X)`, {
                    x: 300,
                    y: height - 2 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`15-17, Jalan SS6/12 `, {
                    x: 60,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Kelana Jaya, 47301 Petaling Jaya,`, {
                    x: 60,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `Tel: 012-818 1647  Email: support@ssrmotorsport.com`,
                    {
                        x: 60,
                        y: height - 5 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Public Bank: 3237512922 (Speed Star Racing Motorsport)`, {
                    x: 60,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Code:`, {
                    x: 370,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.invoiceId}`, {
                    x: 410,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date:`, {
                    x: 370,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${moment(original.createdAt).format("DD/MM/YYYY")}`, {
                    x: 410,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Page:`, {
                    x: 370,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${i + 1}`, {
                    x: 410,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Delivery Order`, {
                    x: 230,
                    y: height - 8 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`From:`, {
                    x: 60,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Speed Star Racing Motorsport,`, {
                    x: 60,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.createdBy.address.lineOne}`, {
                    x: 60,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.createdBy.address.lineTwo}`, {
                    x: 60,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original.createdBy.address.zip} ${original.createdBy.address.city}, ${original.createdBy.address.country}`,
                    {
                        x: 60,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Delivered To:`, {
                    x: 370,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.name},`, {
                    x: 370,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.address.lineOne}`, {
                    x: 370,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.address.lineTwo} ${original.userId.address.zip}`, {
                    x: 370,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original.userId.address.city}, ${original.userId.address.country}`,
                    {
                        x: 370,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 14.25 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 14.25 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`No`, {
                    x: 100,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Item`, {
                    x: 150,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Quantity`, {
                    x: 450,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });


                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 15.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 15.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${index + 1}`, {
                        x: 100,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(`${product.code}`, {
                        x: 150,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(`${product.quantity}`, {
                        x: 450,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 33 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 33 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Remarks:`, {
                    x: 85,
                    y: height - 33.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 34 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 34 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Please check and receive delivery of above mentioned goods in Good order & Condition. Any discrepancy in the bill or tampering`, {
                    x: 85,
                    y: height - 34.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`with the packaging seal, should at once be brought to the attention of the person concerned and will be dealt with accordingly.`, {
                    x: 85,
                    y: height - 35 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Goods not in order must be returned for replacement within 14 days from date herein.`, {
                    x: 85,
                    y: height - 35.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                
                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 39.75 * fontSize,
                    },
                    end: {
                        x: 220,
                        y: height - 39.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Authorised Signature", {
                    x: 105,
                    y: height - 40.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 360,
                        y: height - 39.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 39.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Customer Signature", {
                    x: 405,
                    y: height - 40.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });
            } else {
                const pageData = original.products.slice(10 + (i - 1) * 10, 10 + i * 10);

                page.drawText(`SPEED STAR RACING MOTORSPORT`, {
                    x: 60,
                    y: height - 2 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`202403122361 (003600128-X)`, {
                    x: 300,
                    y: height - 2 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`15-17, Jalan SS6/12  `, {
                    x: 60,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                 page.drawText(`Kelana Jaya, 47301 Petaling Jaya,`, {
                    x: 60,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `Tel: 012-818 1647   Email: support@ssrmotorsport.com`,
                    {
                        x: 60,
                        y: height - 5 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Public Bank: 3237512922 (Speed Star Racing Motorsport)`, {
                    x: 60,
                    y: height - 6 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Code:`, {
                    x: 370,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.invoiceId}`, {
                    x: 410,
                    y: height - 3 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date:`, {
                    x: 370,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${moment(original.createdAt).format("DD/MM/YYYY")}`, {
                    x: 410,
                    y: height - 4 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Page:`, {
                    x: 370,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${i + 1}`, {
                    x: 410,
                    y: height - 5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Delivery Order`, {
                    x: 230,
                    y: height - 8 * fontSize,
                    size: 14,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`From:`, {
                    x: 60,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Speed Star Racing Motorsport,`, {
                    x: 60,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.createdBy.address.lineOne}`, {
                    x: 60,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.createdBy.address.lineTwo}`, {
                    x: 60,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original.createdBy.address.zip} ${original.createdBy.address.city}, ${original.createdBy.address.country}`,
                    {
                        x: 60,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Delivered To:`, {
                    x: 370,
                    y: height - 9 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.name},`, {
                    x: 370,
                    y: height - 10 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.address.lineOne}`, {
                    x: 370,
                    y: height - 11 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.address.lineTwo} ${original.userId.address.zip}`, {
                    x: 370,
                    y: height - 12 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original.userId.address.city}, ${original.userId.address.country}`,
                    {
                        x: 370,
                        y: height - 13 * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 14.25 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 14.25 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`No`, {
                    x: 100,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Item`, {
                    x: 180,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Amount`, {
                    x: 360,
                    y: height - 15 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 15.5 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 15.5 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${index + 1 + (i * 10)}`, {
                        x: 100,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(`${product.code}`, {
                        x: 150,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(`${product.quantity}`, {
                        x: 450,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(``, {
                        x: 330,
                        y: height - (16.5 + index * 1.5) * fontSize,
                        size: 10,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 30.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 30.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 33 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 33 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Remarks:`, {
                    x: 85,
                    y: height - 33.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 34 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 34 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`Please check and receive delivery of above mentioned goods in Good order & Condition. Any discrepancy in the bill or tampering`, {
                    x: 85,
                    y: height - 34.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`with the packaging seal, should at once be brought to the attention of the person concerned and will be dealt with accordingly.`, {
                    x: 85,
                    y: height - 35 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Goods not in order must be returned for replacement within 14 days from date herein.`, {
                    x: 85,
                    y: height - 35.5 * fontSize,
                    size: 8,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                
                page.drawLine({
                    start: {
                        x: 60,
                        y: height - 39.75 * fontSize,
                    },
                    end: {
                        x: 220,
                        y: height - 39.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Authorised Signature", {
                    x: 105,
                    y: height - 40.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 360,
                        y: height - 39.75 * fontSize,
                    },
                    end: {
                        x: 520,
                        y: height - 39.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText("Customer Signature", {
                    x: 405,
                    y: height - 40.5 * fontSize,
                    size: 10,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });
            }
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(pdfBytes, `deliveryForm-${moment(original.createdAt).format("DD/MM/YYYY")}`, "application/pdf");
    } catch (err) {
        alert("Customer must have address to generate delivery order form.");
        console.log(err);
    }
};

export default createDeliveryOrderForm;
