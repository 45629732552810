import "./home.css";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Statement from "../orderList/StatementList";

export default function Home() {
    return (
        <>
            <Topbar />
            <div className="container">
                <Sidebar />
                <div className="home">
                    <Statement></Statement>
                </div>
            </div>
        </>
    );
}
