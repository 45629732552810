import download from "downloadjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import moment from "moment";

const createBulk = async (original) => {
    console.log(original)
    try {
        const NumberFormatter = new Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        let addressArray = [];

        for (let i = 0; i < original.address.length; i += 60) {
            addressArray.push(original.address.substring(i, i + 60));
        }

        const totalPages =
            original.products.length <= 10
                ? 1
                : Math.ceil((original.products.length - 10) / 10 + 1);

        // Create a new PDFDocument
        const pdfDoc = await PDFDocument.create();

        // Embed the Times Roman font
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

        // Get the width and height of the page

        // Add a blank page to the document
        for (let i = 0; i < totalPages; i++) {
            // Add a blank page to the document
            const page = pdfDoc.addPage();
            const fontSize = 20;
            const { height } = page.getSize();
            let totalAmount = 0;

            for (const product of original.products) {
                totalAmount = totalAmount + product.price * product.amount;
            }

            if (i === totalPages - 1) {
                const pageData = original.products.slice(i * 10, (i + 1) * 10);
                const { height } = page.getSize();

                const jpgUrl =
                    "https://ik.imagekit.io/dnddecpho/SSR/ssrtopbanner_YwAGXwtE9_2aaaGeHvD.jpg?updatedAt=1717214999368";
                const jpgImageBytes = await fetch(jpgUrl).then((res) =>
                    res.arrayBuffer()
                );

                const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
                const jpgDims = jpgImage.scale(0.5);
                page.drawImage(jpgImage, {
                    x: 0,
                    y: height - 7 * fontSize,
                    width: jpgDims.width,
                    height: jpgDims.height,
                });

                page.drawText(`Invoice ID:`, {
                    x: 60,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Customer Statement`, {
                    x: 180,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date of Issue:`, {
                    x: 60,
                    y: height - 10.3 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${moment(original.createdAt).format("DD MMM YYYY")}`,
                    {
                        x: 180,
                        y: height - 10.3 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Outlet Contact:`, {
                    x: 60,
                    y: height - 11 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.createdBy.phoneNumber}`, {
                    x: 180,
                    y: height - 11 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Outlet Address:`, {
                    x: 60,
                    y: height - 11.7 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original.createdBy.address.lineOne}${original.createdBy.address.lineTwo}`,
                    {
                        x: 180,
                        y: height - 11.7 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(
                    `${original.createdBy.address.zip}, ${original.createdBy.address.city}, ${original.createdBy.address.country}`,
                    {
                        x: 180,
                        y: height - 12.4 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Customer:`, {
                    x: 60,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.name}`, {
                    x: 180,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Car:`, {
                    x: 60,
                    y: height - 14.5 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                if (original.plate) {
                    page.drawText(
                        `${original.car} - ${original.plate}`,
                        {
                            x: 180,
                            y: height - 14.5 * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                } else {
                    page.drawText(
                        `${original.userId.car} - ${original.userId.username}`,
                        {
                            x: 180,
                            y: height - 14.5 * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                }



                page.drawText(`Mileage:`, {
                    x: 60,
                    y: height - 15.2 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });


                if (original.plate) {
                    page.drawText(`${original.mileage}km`, {
                        x: 180,
                        y: height - 15.2 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                } else {
                    page.drawText(`${original.userId.email}km`, {
                        x: 180,
                        y: height - 15.2 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                }

                page.drawText(`Phone Number:`, {
                    x: 60,
                    y: height - 15.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.phoneNumber}`, {
                    x: 180,
                    y: height - 15.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Address:`, {
                    x: 60,
                    y: height - 16.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                addressArray.forEach((address, index) => {
                    page.drawText(`${address}`, {
                        x: 180,
                        y: height - (16.6 + (index - index * 0.25)) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                });

                page.drawText("No", {
                    x: 50,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Invoice", {
                    x: 90,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Number Plate", {
                    x: 200,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Purchase Date", {
                    x: 350,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Total Price", {
                    x: 480,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - 19.75 * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - 19.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${index + 1}`, {
                        x: 50,
                        y: height - (21 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                        weight: 600,
                    });

                    page.drawText(`${product.invoiceId}`, {
                        x: 90,
                        y: height - (21 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(`${product.plate}`, {
                        x: 200,
                        y: height - (21 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            product.productdate}`,
                        {
                            x: 350,
                            y: height - (21 + index) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(
                            product.price * product.quantity
                        )}`,
                        {
                            x: 480,
                            y: height - (21 + index) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                });

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - (23 + pageData.length) * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - (23 + pageData.length) * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                page.drawText(`SUB TOTAL:`, {
                    x: 350,
                    y: height - (24 + pageData.length) * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${
                        process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"
                    } ${NumberFormatter.format(totalAmount)}`,
                    {
                        x: 480,
                        y: height - (24 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                if (original.discount !== 0) {
                    page.drawText(`DISCOUNT:`, {
                        x: 350,
                        y: height - (25 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(original.discount)}`,
                        {
                            x: 480,
                            y: height - (25 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                }

                if (original.credit) {
                    page.drawText(`TRADE IN:`, {
                        x: 350,
                        y: height - (26 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(original.credit.credit)}`,
                        {
                            x: 480,
                            y: height - (26 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                }

                page.drawText(`TOTAL:`, {
                    x: 350,
                    y: height - (27 + pageData.length) * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${
                        process.env.REACT_APP_COUNTRY === "SINGAPORE"
                            ? "$"
                            : "RM"
                    } ${NumberFormatter.format(
                        totalAmount -
                            original.discount -
                            (original.credit ? original.credit.credit : 0)
                    )}`,
                    {
                        x: 480,
                        y: height - (27 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                if (original.deposit !== 0 && original.status === "deposit") {
                    page.drawText(`DEPOSIT:`, {
                        x: 350,
                        y: height - (28 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(original.deposit)}`,
                        {
                            x: 480,
                            y: height - (28 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );

                    page.drawText(`REMAINING:`, {
                        x: 350,
                        y: height - (29 + pageData.length) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(
                            totalAmount - original.discount - original.deposit
                        )}`,
                        {
                            x: 480,
                            y: height - (29 + pageData.length) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                }

                const btmBannerUrl =
                    "https://ik.imagekit.io/dnddecpho/SSR/ssrbtmbanner_cHXzOtIGU.jpg?updatedAt=1717163705163";
                const btmBannerImageBytes = await fetch(btmBannerUrl).then(
                    (res) => res.arrayBuffer()
                );
                const btmBannerImage = await pdfDoc.embedJpg(
                    btmBannerImageBytes
                );
                const btmBannerDims = btmBannerImage.scale(0.5);
                page.drawImage(btmBannerImage, {
                    x: 0,
                    y: height - 42.5 * fontSize,
                    width: btmBannerDims.width,
                    height: btmBannerDims.height,
                });

            } else {
                const pageData = original.products.slice(
                    10 + (i - 1) * 10,
                    10 + i * 10
                );

                const jpgUrl =
                    "https://ik.imagekit.io/dnddecpho/SSR/ssrtopbanner_YwAGXwtE9_2aaaGeHvD.jpg?updatedAt=1717214999368";
                const jpgImageBytes = await fetch(jpgUrl).then((res) =>
                    res.arrayBuffer()
                );

                const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
                const jpgDims = jpgImage.scale(0.5);
                page.drawImage(jpgImage, {
                    x: 0,
                    y: height - 7 * fontSize,
                    width: jpgDims.width,
                    height: jpgDims.height,
                });

                page.drawText(`Invoice ID:`, {
                    x: 60,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Customer Statement`, {
                    x: 180,
                    y: height - 9.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Date of Issue:`, {
                    x: 60,
                    y: height - 10.3 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${moment(original.createdAt).format("DD MMM YYYY")}`,
                    {
                        x: 180,
                        y: height - 10.3 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Outlet Contact:`, {
                    x: 60,
                    y: height - 11 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.createdBy.phoneNumber}`, {
                    x: 180,
                    y: height - 11 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Outlet Address:`, {
                    x: 60,
                    y: height - 11.7 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original.createdBy.address.lineOne}${original.createdBy.address.lineTwo}`,
                    {
                        x: 180,
                        y: height - 11.7 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(
                    `${original.createdBy.address.zip}, ${original.createdBy.address.city}, ${original.createdBy.address.country}`,
                    {
                        x: 180,
                        y: height - 12.4 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Customer:`, {
                    x: 60,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.name}`, {
                    x: 180,
                    y: height - 13.8 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Car:`, {
                    x: 60,
                    y: height - 14.5 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(
                    `${original.userId.car} - ${original.userId.username}`,
                    {
                        x: 180,
                        y: height - 14.5 * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    }
                );

                page.drawText(`Mileage:`, {
                    x: 60,
                    y: height - 15.2 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.email}km`, {
                    x: 180,
                    y: height - 15.2 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Phone Number:`, {
                    x: 60,
                    y: height - 15.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`${original.userId.phoneNumber}`, {
                    x: 180,
                    y: height - 15.9 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText(`Address:`, {
                    x: 60,
                    y: height - 16.6 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                addressArray.forEach((address, index) => {
                    page.drawText(`${address}`, {
                        x: 180,
                        y: height - (16.6 + (index - index * 0.25)) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });
                });

                page.drawText("No", {
                    x: 50,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Item", {
                    x: 90,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Purchase Date", {
                    x: 350,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawText("Total Price", {
                    x: 480,
                    y: height - 19.25 * fontSize,
                    size: 11,
                    font: timesRomanFont,
                    color: rgb(0, 0, 0),
                });

                page.drawLine({
                    start: {
                        x: 30,
                        y: height - 19.75 * fontSize,
                    },
                    end: {
                        x: 560,
                        y: height - 19.75 * fontSize,
                    },
                    color: rgb(0, 0, 0),
                    opacity: 0.75,
                });

                pageData.forEach((product, index) => {
                    page.drawText(`${index + 1}`, {
                        x: 50,
                        y: height - (21 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                        weight: 600,
                    });

                    page.drawText(`${product.quantity} x ${product.code}`, {
                        x: 90,
                        y: height - (21 + index) * fontSize,
                        size: 11,
                        font: timesRomanFont,
                        color: rgb(0, 0, 0),
                    });

                    page.drawText(
                        `${
                            product.productdate}`,
                        {
                            x: 350,
                            y: height - (21 + index) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );

                    page.drawText(
                        `${
                            process.env.REACT_APP_COUNTRY === "SINGAPORE"
                                ? "$"
                                : "RM"
                        } ${NumberFormatter.format(
                            product.price * product.quantity
                        )}`,
                        {
                            x: 480,
                            y: height - (21 + index) * fontSize,
                            size: 11,
                            font: timesRomanFont,
                            color: rgb(0, 0, 0),
                        }
                    );
                });

                const btmBannerUrl =
                    "https://ik.imagekit.io/dnddecpho/SSR/ssrbtmbanner_cHXzOtIGU.jpg?updatedAt=1717163705163";
                const btmBannerImageBytes = await fetch(btmBannerUrl).then(
                    (res) => res.arrayBuffer()
                );
                const btmBannerImage = await pdfDoc.embedJpg(
                    btmBannerImageBytes
                );
                const btmBannerDims = btmBannerImage.scale(0.5);
                page.drawImage(btmBannerImage, {
                    x: 0,
                    y: height - 42.5 * fontSize,
                    width: btmBannerDims.width,
                    height: btmBannerDims.height,
                });
            }
        }

        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save();

        // Trigger the browser to download the PDF document
        download(
            pdfBytes,
            `ssr-ereceipt-${original.userId.name}`,
            "application/pdf"
        );
    } catch (err) {
        alert("Failed");
        console.log(err);
    }
};

export default createBulk;
