import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userRedux";
import productReducer from "./productRedux";
import cartReducer from "./cartRedux";
import warrantyReducer from "./warrantyRedux";
import affiliateReducer from "./affiliateRedux";
import campaignReducer from "./campaignRedux";
import orderReducer from "./orderRedux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import expireReducer from "redux-persist-transform-expire";

const persistConfig = {
  key: "ssrroot",
  version: 1,
  storage,
  transforms: [
    expireReducer("ssrroot", {
      expireSeconds: 30, // 30 seconds
      purgeOnExpire: true,
    }),
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  product: productReducer,
  cart: cartReducer,
  warranty: warrantyReducer,
  order: orderReducer,
  affiliate: affiliateReducer,
  campaign: campaignReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
