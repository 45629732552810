import { useState, useEffect } from "react";
import "./newProduct.css";
import { addProduct } from "../../redux/apiCalls";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { publicRequest } from "../../requestMethods";

export default function NewProduct() {
    const [inputs, setInputs] = useState({});
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    const getAllBrands = async () => {
        const res = await publicRequest.get("/brand/all", {
            params: { isAdmin: true },
        });
        setBrands(res.data);
    };

    useEffect(() => {
        if (brands.length === 0) {
            getAllBrands();
        }
    }, [brands]);

    const getAllCategories = async () => {
        const res = await publicRequest.get("/category/all", {
            params: { isAdmin: true },
        });
        setCategories(res.data);
    };

    useEffect(() => {
        if (categories.length === 0) {
            getAllCategories();
        }
    }, [categories]);

    const handleChange = (e) => {
        setInputs((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleBrandChange = (e) => {
        setInputs((prev) => {
            return { ...prev, brand: e.value };
        });
    };

    const handleCategoryChange = (e) => {
        setInputs((prev) => {
            return { ...prev, category: e.value };
        });
    };


    const handleClick = async () => {
        let imageupload = "https://ik.imagekit.io/dnddecpho/Haofa/blank_2YRK1Fq9C.png?updatedAt=1680849534401"
        
        const product = {
            ...inputs,
            img: imageupload
        };
        addProduct(product, dispatch);
    };

    return (
        <div className="newProduct">
            <h1 className="addProductTitle">New Product</h1>
            <div className="addProductForm">
                <div className="addProductItem">
                    <label>Category</label>
                    <Select
                        options={categories.map((product, i) => {
                            return { label: product.name, value: product._id };
                        })}
                        onChange={handleCategoryChange}
                    />
                </div>

                <div className="addProductItem">
                    <label>Brand</label>
                    <Select
                        options={brands.map((product, i) => {
                            return { label: product.name, value: product._id };
                        })}
                        onChange={handleBrandChange}
                    />
                </div>

                <div className="addProductItem">
                    <label>Product Name</label>
                    <input
                        name="title"
                        type="text"
                        placeholder="Product Name"
                        onChange={handleChange}
                    />
                </div>

                <button onClick={handleClick} className="addProductButton">
                    Create
                </button>
            </div>
        </div>
    );
}
