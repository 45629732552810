import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDeliveryWarranties, transferStocks } from "../../redux/apiCalls";
import moment from "moment";
import { publicRequest } from "../../requestMethods";
import Select from "react-select";
import Table from "../../components/advancedTable";
import outletList from "../../outletList";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import styled from "styled-components";

const ModalContainer = styled.div`
    height: 100%;
    width: 100%;
    opacity: 0.75;
    background: black;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100;
`;

const OverlayContainer = styled.div`
    overflow: hidden;
`;

const Overlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    left: 0;
    width: 100%;
    height: 50%;
    top: 25%;
    background-color: rgba(white, 0.5);
    visibility: visible;
`;

const ModalDetails = styled.div`
    background-color: rgba(white, 0.5);
    color: black;
    height: 100%;
    width: 50%;
    display: flex;
    z-index: 102;
`;

const ModalItemContainer = styled.div`
    background-color: white;
    color: black;
    height: 100%;
    width: 100%;
    display: grid;
`;

export default function StockList() {
    const dispatch = useDispatch();
    const superadmin = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isSuperAdmin : null
    );
    const adminOutlet = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser._id : null
    );

    const manager = useSelector((state) =>
        state.user.currentUser
            ? state.user.currentUser.type === "manager"
            : null
    );


    const stockKeeper = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.isStockKeeper : null
    );

    const staff = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.type === "staff" : null
    );

    const principle = useSelector((state) =>
        state.user.currentUser ? state.user.currentUser.principle : null
    );
    const [isLoading, setIsLoading] = useState(false);
    const warranties = useSelector((state) => state.warranty.warranties);
    const [isLoaded, setIsLoaded] = useState(false);
    const [warrantyData, setWarrantyData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [checklist, setChecklist] = useState([]);
    const [editModalState, setEditModalState] = useState(false);
    const [serialNumber, setSerialNumber] = useState(undefined);
    const [outlet, setOutlet] = useState(undefined);
    const [fromOutlet, setFromOutlet] = useState(
        stockKeeper ? undefined : adminOutlet
    );
    const [inputs, setInputs] = useState({
        model: "",
        userId: manager ? principle : staff ? principle : adminOutlet,
        limit: 100,
        page: 1,
        status: "inactive",
        serialNumber: "",
        dateFrom: undefined,
        endOn: undefined,
        outlet: "",
    });
    const [usersData, setUsersData] = useState([]);

    const getUsers = async () => {
        const res = await publicRequest.get("/users/alladmin", {
            params: { isAdmin: true },
        });
        setUsersData(res.data);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (isLoaded === false) {
                try {
                    setIsLoading(true);
                    const result = await getDeliveryWarranties(
                        dispatch,
                        inputs
                    );
                    if (result) {
                        setIsLoading(false);
                        setAllData(result.payload);
                        setWarrantyData(result.payload);
                        setIsLoaded(true);
                    }
                } catch (error) {
                    console.error("Error fetching warranties:", error);
                }
            }
        };

        if (warrantyData[0] === undefined) {
            fetchData();
        }
    }, [dispatch, inputs, warrantyData, isLoaded]);

    useEffect(() => {
        if (usersData[0] === undefined) {
            getUsers();
        }
    }, [usersData]);

    const handleOutletChange = (e) => {
        setWarrantyData(
            allData.filter((warranty) => {
                if (
                    warranty.outlet._id
                        .toString()
                        .includes(e.value.toString()) &&
                    (serialNumber === undefined ||
                        warranty.serialNumber.includes(serialNumber))
                ) {
                    return true; // Return true if the condition is met
                }
                return false; // Return false otherwise
            })
        );
        setFromOutlet(e.value);
    };

    const handleChange = (e) => {
        const value = e.target.value.trim(); // Trim whitespace

        setWarrantyData(
            allData.filter((warranty) => {
                if (
                    warranty.serialNumber.toString().includes(value) &&
                    (serialNumber === undefined ||
                        warranty.outlet._id.toString().includes(outlet))
                ) {
                    return true; // Return true if the condition is met
                }
                return false; // Return false otherwise
            })
        );

        setSerialNumber(value);
    };

    const hqColumns = useMemo(
        () => [
            {
                accessor: "",
                Header: "Checkbox",
                width: 50,
                sortable: false,
                Cell: (params) => {
                    const [isChecked, setIsChecked] = useState(false);
                
                    const handleCheckboxClick = (serialNumber) => {
                        setIsChecked(true)
                        // Add or remove serial number from the checklist based on its presence
                        if (checklist.includes(serialNumber)) {
                            // Remove serial number if it exists in the checklist
                            setChecklist(prevChecklist => prevChecklist.filter(item => item !== serialNumber));
                        } else {
                            // Add serial number if it doesn't exist in the checklist
                            setChecklist(prevChecklist => [...prevChecklist, serialNumber]);
                        }
                    };
                
                    const handleUnCheckboxClick = (serialNumber) => {
                        // Remove the serial number from the checklist
                        setIsChecked(false)
                        setChecklist(prevChecklist => prevChecklist.filter(item => item !== serialNumber));
                    };
                
                    // Render the div only if the checkbox is not checked
                    return (
                        <>
                            {/* Inside your JSX where you render the checkbox */}
                            {isChecked ? (
                                <div
                                    className="capitalize cursor-pointer"
                                    onClick={() => handleUnCheckboxClick(params.row.original._id)}
                                >
                                    <CheckBox />
                                </div>
                            ) : (
                                <div
                                    className="capitalize cursor-pointer"
                                    onClick={() => handleCheckboxClick(params.row.original._id)}
                                >
                                    <CheckBoxOutlineBlank />
                                </div>
                            )}
                        </>
                    );
                },
                
            },
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value.name : ""}</div>
                ),
            },
            {
                accessor: "createdAt",
                Header: "Start Date",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value ? moment(value).format("DD-MM-YYYY") : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
        ],
        // eslint-disable-next-line
        []
    );

    const columns = useMemo(
        () => [
            {
                accessor: "serialNumber",
                Header: "Serial Number",
                width: 200,
                sortable: false,
            },
            {
                accessor: "productColor",
                Header: "Model",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">{value ? value : ""}</div>
                ),
            },
            {
                accessor: "user",
                Header: "User",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {value ? (value.name ? value.name : "") : ""}
                    </div>
                ),
            },
            {
                accessor: "outlet",
                Header: "Outlet",
                width: 200,
                sortable: false,
                Cell: ({ value }) => {
                    const selectedOutlet = outletList.find(
                        (outlet) => outlet.id === value
                    );
                    return (
                        <div className="capitalize">
                            {value
                                ? selectedOutlet
                                    ? selectedOutlet.name
                                    : ""
                                : ""}
                        </div>
                    );
                },
            },
            {
                accessor: "createdAt",
                Header: "Start Date",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value ? moment(value).format("DD-MM-YYYY") : ""}
                    </div>
                ),
            },
            {
                accessor: "startDate",
                Header: "Warranty Until",
                width: 150,
                sortable: false,
                Cell: ({ value }) => (
                    <div className="capitalize">
                        {" "}
                        {value
                            ? moment(value).add(3, "y").format("DD-MM-YYYY")
                            : ""}
                    </div>
                ),
            },
            {
                accessor: "status",
                Header: "Status",
                width: 200,
                sortable: false,
                Cell: ({ value }) => <div className="capitalize">{value}</div>,
            },
        ],
        []
    );

    return (
        <>
            {isLoading ? (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                <div className="productList ">
                    <div className="samerow">
                        <div className="addProductItem">
                            <input
                                className="inputFilter"
                                name="serialNumber"
                                value={serialNumber}
                                type="string"
                                placeholder="Serial Number"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="sameRowItem marginRight">
                            {superadmin && (
                                <Select
                                    options={usersData.map((user, i) => {
                                        return {
                                            label: `${user.name}`,
                                            value: user._id,
                                        };
                                    })}
                                    onChange={handleOutletChange}
                                    placeholder={"Outlet"}
                                    onClick={() =>
                                        usersData[0] === undefined
                                            ? getUsers()
                                            : {}
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="block">
                        <>
                            <button
                                onClick={() => {
                                    console.log(fromOutlet)
                                    if (fromOutlet === undefined) {
                                        alert("Please select an outlet first.");
                                    } else {
                                        setEditModalState(true);
                                    }
                                }}
                                className="green buttonTop productListButton"
                            >
                                Transfer
                            </button>
                        </>
                    </div>
                    {isLoaded === true && (
                        <Table
                            data={warrantyData}
                            columns={superadmin ? hqColumns : columns}
                            pageCount={
                                Math.floor(warranties.totalPages / 10) + 2
                            }
                            totalCount={warranties.totalPages}
                            updatePagination={setInputs}
                            paginationState={inputs}
                            updateLoaded={setIsLoaded}
                            hidePagination={inputs.limit === 10 ? false : true}
                        />
                    )}
                </div>
                {editModalState && (
                <OverlayContainer>
                    <ModalContainer
                        onClick={() => setEditModalState(false)}
                    ></ModalContainer>
                    <Overlay>
                        <ModalDetails>
                            <ModalItemContainer>
                                <div className="updateTitle">
                                    <form className="addProductForm">
                                    <Select
                        className="borderBlack"
                        options={usersData.map((user, i) => {
                            return {
                                label: `${user.name} - ${user.country === "malaysia" ?  "MY" : "SG"}`,
                                value: user._id,
                            };
                        })}
                        placeholder={"Outlet Name - Country"}
                        name="receiver"
                        onChange={(e) => setOutlet(e.value)}
                    />
                                    
                                        <div
                                            onClick={() => {
                                                const payload = {
                                                    outlet: outlet,
                                                    fromOutlet,
                                                    warranties: checklist,
                                                };
                                                if (checklist.length === 0) {
                                                alert("Please select at least one item to transfer.");
                                                return;
                                                }
                                                else if (fromOutlet === outlet) {
                                                    alert("You cannot transfer to the same outlet.");
                                                    return;
                                                  }
                                                 else {
                                                transferStocks(payload, dispatch);
                                                }
                                            }}
                                            className="updateProductButton"
                                        >
                                            Transfer Stocks to Outlet
                                        </div>
                                    </form>
                                </div>
                            </ModalItemContainer>
                        </ModalDetails>
                    </Overlay>
                </OverlayContainer>
            )}
                </>
            )}
        </>
    );
}
